import React, { useEffect, useState } from "react";
import CustomProgressBar from "components/CustomProgressBar";
import SimpleCountDown from "components/Sliders/SimpleCountDown";
import Heart from "assets/img/Heart_empty.webp";
import HeartFull from "assets/img/Heart_full.webp";
import { CooperativeGameDisplayType } from "constants/games.constants";
import { useMemoizedState } from "helpers/stateHelper";

function CooperativeGamePrivate({ gameDisplayType, showHearts, runningGameResult, isPlaying, setRemainingTime }) {
   console.log("gameDisplayType", gameDisplayType);
   if (gameDisplayType === undefined || runningGameResult === undefined) {
      return <React.Fragment />;
   }

   console.log("CooperativeGamePrivate", runningGameResult);
   return (
      <div className="container">
         {showHearts && (
            <div className="row row-heart">
               <div className="col-md-12">
                  <img src={runningGameResult.hearts > 4 ? HeartFull : Heart} />
                  <img src={runningGameResult.hearts > 3 ? HeartFull : Heart} />
                  <img src={runningGameResult.hearts > 2 ? HeartFull : Heart} />
                  <img src={runningGameResult.hearts > 1 ? HeartFull : Heart} />
                  <img src={runningGameResult.hearts > 0 ? HeartFull : Heart} />
               </div>
            </div>
         )}

         <div className="row pl8 pr8">
            {gameDisplayType === CooperativeGameDisplayType.NONE ? (
               <div className="col-md-12">
                  <div style={{ margin: "5% auto 5% auto", width: "40%" }}>
                     <SimpleCountDown
                        startFrom={runningGameResult.playtime}
                        duration={runningGameResult.playtime}
                        isPlaying={isPlaying}
                        setRemainingTime={setRemainingTime}
                     />
                  </div>
               </div>
            ) : (
               <div className="col-md-6 progress-bar-box">
                  <SimpleCountDown
                     startFrom={runningGameResult.playtime}
                     duration={runningGameResult.playtime}
                     isPlaying={isPlaying}
                     setRemainingTime={setRemainingTime}
                  />
               </div>
            )}
            {gameDisplayType === CooperativeGameDisplayType.QUESTION && <div className="col-md-6 div-score">{renderQuestion(runningGameResult)}</div>}
            {gameDisplayType === CooperativeGameDisplayType.PROGRESS_BAR && (
               <div className="col-md-6 div-score">{renderProgressBar(runningGameResult)}</div>
            )}
            {gameDisplayType === CooperativeGameDisplayType.BULLETS && (
               <div className="col-md-6 div-score">{<Bullets runningGameResult={runningGameResult} />}</div>
            )}
            {gameDisplayType === CooperativeGameDisplayType.TEXT && <div className="col-md-6 div-score">{renderText(runningGameResult)}</div>}
         </div>
      </div>
   );
}

const renderProgressBar = runningGameResult => {
   const progress = runningGameResult.cooperativescore === null ? 0 : runningGameResult.cooperativescore / (runningGameResult.goal ?? 0);
   return <CustomProgressBar value={progress * 100} text={`${runningGameResult.cooperativescore ?? 0}/${runningGameResult.goal ?? 0}`} />;
};

function Bullets({ runningGameResult }) {
   const [currentState, setCurrentState] = useMemoizedState({ pattern: "", score: 0 });
   const [circles, setCircles] = useMemoizedState({ empty: runningGameResult.colorpatternid?.patternList?.length ?? 8, filled: 0 });

   useEffect(() => {
      const newColorPatternId = runningGameResult.colorpatternid?.patternList.map(x => x.colorid.id).join("");
      if (newColorPatternId) {
         setCurrentState({
            pattern: newColorPatternId,
            score: runningGameResult.cooperativescore,
         });
      }
   }, [runningGameResult]);

   useEffect(() => {
      const patternColorsNb = runningGameResult.colorpatternid?.patternList?.length ?? 0;

      let nbOfFilledCircles = runningGameResult.cooperativescore % patternColorsNb;
      if (runningGameResult.cooperativescore > 0 && nbOfFilledCircles === 0) {
         nbOfFilledCircles = patternColorsNb;
      }

      setCircles({
         filled: nbOfFilledCircles,
         empty: patternColorsNb - nbOfFilledCircles,
      });
   }, [currentState.score]);

   useEffect(() => {
      const patternColorsNb = runningGameResult.colorpatternid?.patternList?.length ?? 8;

      setCircles({
         filled: 0,
         empty: patternColorsNb,
      });
   }, [currentState.pattern]);

   return (
      <div className="div-cerc">
         {circles.filled > 0 &&
            runningGameResult.colorpatternid.patternList.slice(0, circles.filled).map((x, key) => {
               return (
                  <div
                     className={`cerc sc-${x.colorid.name}`}
                     style={{
                        backgroundColor: x.colorid.rgbval,
                     }}
                     key={key}
                  ></div>
               );
            })}
         {[...Array(circles.empty)].map((x, key) => (
            <div className="cerc" key={key + circles.filled}></div>
         ))}
      </div>
   );
}
const renderText = runningGameResult => {
   // TODO bogdanla: From where do i take the TEXT ??
   // TODO bogdanla: Also not sure about design. Ask Alin to check again
   const word = runningGameResult?.wordid?.word ?? "";

   return (
      <div className="w-100">
         <p className={"text-team font4"}>{word}</p>
      </div>
   );
};

const renderQuestion = runningGameResult => {
   const question = runningGameResult.questionid;

   console.log(runningGameResult.questionid);
   const renderDefaultAnswerList = () => {
      return (
         <>
            <div className={`div-sc skew-0 sc-red progress-sc progress-100`}>1</div>
            <div className={`div-sc skew-0 sc-green progress-sc progress-100`}>2</div>
            <div className={`div-sc skew-0 sc-blue progress-sc progress-100`}>3</div>
            <div className={`div-sc skew-0 sc-violet progress-sc progress-100`}>4</div>
            <div className={`div-sc skew-0 sc-yellow progress-sc progress-100`}>5</div>
         </>
      );
   };

   return (
      <>
         <p className={"text-tv font3 text-center"}>{question?.question ?? ""}</p>
         <div className="div-question">
            {question?.answerList?.length > 0
               ? question.answerList.map((x, key) => (
                    <div
                       key={key}
                       className={`div-sc skew-0 sc-${x.colorid.name} progress-sc progress-100`}
                       style={{ backgroundColor: x.colorid.rgbval }}
                    >
                       {x.answer}
                    </div>
                 ))
               : renderDefaultAnswerList()}
         </div>
         {renderProgressBar(runningGameResult)}
      </>
   );
};

const CooperativeGame = React.memo(CooperativeGamePrivate);
export default CooperativeGame;
