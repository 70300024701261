import React from "react";
import { ProgressBar } from "react-bootstrap";

export default function CustomProgressBar({ value, text, color = undefined }) {
   color = color ?? "green";
   return (
      <ProgressBar
         className={`div-progress sc-${color} font-15 progress-sc mt4`}
         now={value}
         style={{
            backgroundColor: { color },
         }}
         label={<div className={"progress-label"}>{text}</div>}
      />
   );
}
