import React, { useState, useEffect } from "react";
import Badge from "assets/img/checkscore/icon_token_Energize_mov.webp";
import AccountSummaryCoopCard from "components/AccountSummaryCoopCard";
import { AccountSummaryRankType, AccountSummaryCardTheme } from "components/AccountSummaryData";
import GamesTable from "components/GamesTable";
import GamesRankings from "components/GamesRankings";
import { getAccountSummary, getAccountSummaryCards, getAllRooms } from "api/checkscore";
import helpers from "helpers/helpers";
import gameIcons from "data/games.json";

const CheckScoreResults = ({ userCheckScore, setIsScanned }) => {
   const [gameTypeIsCooperative, setGameTypeIsCooperative] = useState(true);
   const [selectedRoom, setSelectedRoom] = useState("B-ball");
   const [accountSummaryData, setAccountSummaryData] = useState({});
   const [cardsData, setCardsData] = useState({});
   const [rooms, setRooms] = useState([]);
   const colors = ["Violet", "Orange", "Blue", "Red", "Yellow", "Green", "Pink", "Cyan"];
   const [roomId, setRoomId] = useState(1);
   const [gameId, setGameId] = useState(1);

   const roomsWithIcons = rooms.map(value => {
      let obj = gameIcons.find(object => object.id === value.id);
      if (obj) {
         value.iconGreen = obj.iconGreen;
         value.iconRed = obj.iconRed;
      }
      return value;
   });

   useEffect(() => {
      const getAccountSummaryAsync = async () => {
         const response = await getAccountSummary(helpers.getGameType(gameTypeIsCooperative), userCheckScore.nickname);
         setAccountSummaryData(response);
      };
      const getAccountSummaryCardsAsync = async () => {
         const response = await getAccountSummaryCards(userCheckScore.cardUid);
         setCardsData(response);
      };
      const getAllRoomsAsync = async () => {
         const response = await getAllRooms();
         setRooms(response);
      };

      getAccountSummaryAsync();
      getAccountSummaryCardsAsync();
      getAllRoomsAsync();
   }, []);

   return (
      <div className="tablet-container font-style-normal">
         <div className="main-container checkscore-container padding-checkscore">
            <div className="row text-white">
               <div
                  className={`col-md-6 pe-0 text-center checkscore-green-bg-btn bb0 ${
                     gameTypeIsCooperative ? "checkscore-border-button-green font-weight-600" : "no-active-red"
                  }`}
                  onClick={() => setGameTypeIsCooperative(true)}
               >
                  <p className={"font3 mt2 mb2"}>COOPERATIVE</p>
               </div>
               <div
                  className={`col-md-6 pe-0 text-center checkscore-red-bg-btn bb0 ${
                     !gameTypeIsCooperative ? "checkscore-border-button-red font-weight-600" : "no-active-green"
                  }`}
                  onClick={() => setGameTypeIsCooperative(false)}
               >
                  <p className={"font3 mt2 mb2"}>COMPETITIVE</p>
               </div>
            </div>
            <div className="pb-3">
               <div className="row main-border pt-2 pb-2 checkscore-green-bg-acc_summary text-white">
                  <div className="d-flex flex-column col-md-6">
                     <p className="font-22 cff3b1 font-weight-600 mb-0 ms-2">{accountSummaryData?.user?.nickname}</p>
                     <p className="font22 font-weight-400 mb-0 ms-2">
                        RANK: <span className="font-weight-600">{accountSummaryData?.rank}</span>
                     </p>
                  </div>
                  <div className="col-md-2 d-flex justify-content-end ps-0 pe-0">
                     <img className="checkscore-badge" src={Badge} />
                  </div>
                  <div className="d-flex flex-column col-md-4">
                     <p className="font22 font-weight-600 mb-0">{accountSummaryData?.user?.energizeToken}</p>
                     <p className="font22 font-weight-600 mb-0">Energize Tokens</p>
                  </div>
               </div>
            </div>
            {gameTypeIsCooperative && (
               <>
                  <AccountSummaryCoopCard
                     number={cardsData?.currentRank?.userrank}
                     type={AccountSummaryRankType.Current}
                     theme={AccountSummaryCardTheme[colors[cardsData?.currentRank?.userrank]]}
                     accountSummaryData={accountSummaryData}
                     cardsData={cardsData}
                  />
                  <AccountSummaryCoopCard
                     number={cardsData?.nextRank?.userrank}
                     type={AccountSummaryRankType.Next}
                     theme={AccountSummaryCardTheme[colors[cardsData?.nextRank?.userrank]]}
                     accountSummaryData={accountSummaryData}
                     cardsData={cardsData}
                  />
               </>
            )}
            {/* {gameTypeIsCooperative &&
                  achivementsWithNext.map(([nextAchivementPoint, color], index) => {
                     return (
                        <AccountSummaryCoopCard
                           key={index}
                           number={index + 1}
                           type={
                              index == achivementsWithNext.length - 1
                                 ? AccountSummaryRankType.Next
                                 : index == achivementsWithNext.length - 2
                                 ? AccountSummaryRankType.Current
                                 : AccountSummaryRankType.Previous
                           }
                           theme={AccountSummaryCardTheme[color]}
                           nextAchivementPoint={nextAchivementPoint}
                           energizeTokens={energizeTokens}
                        />
                     );
                  })} */}
            <GamesTable
               gameTypeIsCooperative={gameTypeIsCooperative}
               selectedRoom={selectedRoom}
               setSelectedRoom={setSelectedRoom}
               roomsWithIcons={roomsWithIcons}
               setRoomId={setRoomId}
            />
            <GamesRankings
               userCheckScore={userCheckScore}
               gameTypeIsCooperative={gameTypeIsCooperative}
               selectedRoom={selectedRoom}
               roomId={roomId}
               gameId={gameId}
               setGameId={setGameId}
            />
            <div className="mt-5" onClick={() => setIsScanned(false)}>
               <h2 className="font4 text-white font-weight-600 text-center text-uppercase">go back</h2>
            </div>
         </div>
      </div>
   );
};

export default CheckScoreResults;
