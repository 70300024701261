export const CooperativeGameDisplayType = {
   NONE: "NONE", // DOCS: Only countdown
   PROGRESS_BAR: "PROGRESS_BAR",
   QUESTION: "QUESTION",
   BULLETS: "BULLETS",
   TEXT: "TEXT",
};

export const CompetitiveGameDisplayType = {
   NONE: "NONE", // DOCS: Only countdown
   TEAM_SCORE: "TEAM_SCORE", // asta ramane
   NICKNAME_SCORE: "NICKNAME_SCORE", // score fara progress bar
   SCORE: "SCORE", // score fara progress bar
   ELAPSE: "ELAPSE",
   SCORE_WITHOUT_PROGRESS: "SCORE_WITHOUT_PROGRESS",
};

export const GameTypes = {
   Cooperative: "COOPERATIVE",
   Competitive: "COMPETITIVE",
};

export const GameTemplates = [
   {
      id: "countdown",
      showHearts: false,
      gameType: GameTypes.Cooperative,
      gameDisplayType: CooperativeGameDisplayType.NONE,
   },
   {
      id: "countdownHearts",
      showHearts: true,
      gameType: GameTypes.Cooperative,
      gameDisplayType: CooperativeGameDisplayType.NONE,
   },
   {
      id: "countdownProgressBar",
      showHearts: true,
      gameType: GameTypes.Cooperative,
      gameDisplayType: CooperativeGameDisplayType.PROGRESS_BAR,
   },
   {
      id: "countdownQuestion",
      showHearts: true,
      gameType: GameTypes.Cooperative,
      gameDisplayType: CooperativeGameDisplayType.QUESTION,
   },
   {
      id: "countdownBullets",
      showHearts: true,
      gameType: GameTypes.Cooperative,
      gameDisplayType: CooperativeGameDisplayType.BULLETS,
   },
   {
      id: "countdownText",
      showHearts: true,
      gameType: GameTypes.Cooperative,
      gameDisplayType: CooperativeGameDisplayType.TEXT,
   },
   {
      id: "countdownTeamScore",
      showHearts: false,
      gameType: GameTypes.Competitive,
      gameDisplayType: CompetitiveGameDisplayType.TEAM_SCORE,
   },
   {
      id: "countdownNicknameScore",
      showHearts: false,
      gameType: GameTypes.Competitive,
      gameDisplayType: CompetitiveGameDisplayType.NICKNAME_SCORE,
   },
   {
      id: "countdownScore",
      showHearts: false,
      gameType: GameTypes.Competitive,
      gameDisplayType: CompetitiveGameDisplayType.SCORE,
      showName: false,
   },
   {
      id: "countdownElapse",
      showHearts: false,
      gameType: GameTypes.Competitive,
      gameDisplayType: CompetitiveGameDisplayType.ELAPSE,
   },
];

export const GameQuestions = [
   {
      id: 1,
      question: "What is the capital of France?",
      availableAnswers: ["Athena", "Berlin", "Paris", "New York", "Barcelona"],
   },
];
