import React, { useState } from "react";
import Border from "components/Border";
import { Modal } from "react-bootstrap";
import Agree from "../../assets/img/I_agree.webp";
import AgreeDisabled from "../../assets/img/newWaiver/I_Agree_btn_gray.png";

const Waiver = props => {
   const { show, form, setForm, inputTypes, handleInputChage, handleAccept, waiverText, isWaiverForLogin } = props;
   const [ageIsSet, setAgeIsSet] = useState(false);

   const getRadioClassName = age => {
      if (!ageIsSet) {
         // daca nu s-a setat varsta bagam clasa de gri pe ambele
         return "isOver16-nebifat-verde";
      } else if (age === true) {
         return "isOver16-bifat";
      } else if (age === false) {
         return "isOver16-nebifat-gri";
      }
   };

   const getIAgreeButton = () => {
      if (form.waiverName.value && form.waiverSurname.value) return Agree;
      return AgreeDisabled;
   };

   return (
      <Modal show={show} className={`main-modal ${isWaiverForLogin && "modal-login"}`}>
         <Modal.Header>
            <Modal.Title>Walver acceptance</Modal.Title>
         </Modal.Header>
         <Modal.Body>
            <p>{waiverText}</p>

            <div role="group" aria-labelledby="my-radio-group">
               <div className="col-md-12 d-flex mb-2">
                  <input
                     id="isOver16Da"
                     type="radio"
                     name="isOver16"
                     className={`isOver16 ${getRadioClassName(form.age.value)}`}
                     defaultChecked={form.age.value !== null && form.age.value}
                     onChange={() => {
                        setAgeIsSet(true);
                        setForm(prevState => {
                           return {
                              ...prevState,
                              age: {
                                 error: undefined,
                                 value: true,
                              },
                              waiverName: {
                                 error: undefined,
                                 value: "",
                              },
                              waiverSurname: {
                                 error: undefined,
                                 value: "",
                              },
                           };
                        });
                     }}
                  />
                  <label className="isOver16Label" htmlFor="isOver16Da">
                     I confirm that I am over 16 years of age.
                  </label>
               </div>
               {form?.age.value === true && (
                  <>
                     <div className="form-group mb-0 mt-4">
                        <Border isInWaiver={true} />
                        <input
                           value={form.waiverName.value}
                           onChange={e => handleInputChage(e.target.value, inputTypes.waiverName)}
                           placeholder="Name"
                           type="name"
                           className={`${form.waiverName.error !== undefined ? "is-invalid" : "is-valid"}`}
                        />
                        <br />
                        <p className={"error-message m-0"}> {form.waiverName.error !== undefined && form.waiverName.error}</p>
                        <br />
                     </div>
                     <div className="form-group mb-0 mt-0">
                        <Border isInWaiver={true} />
                        <input
                           value={form.waiverSurname.value}
                           onChange={e => handleInputChage(e.target.value, inputTypes.waiverSurname)}
                           placeholder="Surname"
                           type="name"
                           className={`${form.waiverSurname.error !== undefined ? "is-invalid" : "is-valid"}`}
                        />
                        <br />
                        <p className={"error-message m-0"}> {form.waiverSurname.error !== undefined && form.waiverSurname.error}</p>
                        <br />
                     </div>
                  </>
               )}
               <div className="col-md-12 d-flex">
                  <input
                     id="isOver16Nu"
                     type="radio"
                     name="isOver16"
                     className={`isOver16 ${getRadioClassName(!form.age.value)}`}
                     defaultChecked={form.age.value === false && !form.age.value}
                     onChange={() => {
                        setAgeIsSet(true);
                        setForm(prevState => {
                           return {
                              ...prevState,
                              age: {
                                 error: undefined,
                                 value: false,
                              },
                              waiverName: {
                                 error: undefined,
                                 value: "",
                              },
                              waiverSurname: {
                                 error: undefined,
                                 value: "",
                              },
                           };
                        });
                     }}
                  />
                  <label className="isOver16Label" htmlFor="isOver16Nu">
                     I confirm that I am under 16 years of age. A parent or guardian must sign the waiver.
                  </label>
               </div>

               {form?.age.value === false && (
                  <>
                     <div className="form-group mt-4 mb-0">
                        <Border isInWaiver={true} />
                        <input
                           value={form.waiverName.value}
                           onChange={e => handleInputChage(e.target.value, inputTypes.waiverName)}
                           placeholder="Name of Parent/Guardian"
                           type="name"
                           className={`${form.waiverName.error !== undefined ? "is-invalid" : "is-valid"}`}
                        />
                        <br />
                        <p className={"error-message m-0"}> {form.waiverName.error !== undefined && form.waiverName.error}</p>
                        <br />
                     </div>
                     <div className="form-group mb-0">
                        <Border isInWaiver={true} />
                        <input
                           value={form.waiverSurname.value}
                           onChange={e => handleInputChage(e.target.value, inputTypes.waiverSurname)}
                           placeholder="Surname of Parent/Guardian"
                           type="name"
                           className={`${form.waiverSurname.error !== undefined ? "is-invalid" : "is-valid"}`}
                        />
                        <br />
                        <p className={"error-message m-0"}> {form.waiverSurname.error !== undefined && form.waiverSurname.error}</p>
                        <br />
                     </div>
                  </>
               )}
            </div>
            <p className={"error-message relative mb-0"}> {form.age.error !== undefined && form.age.error}</p>

            <img className="w-img2" src={getIAgreeButton()} onClick={handleAccept} />
         </Modal.Body>
      </Modal>
   );
};
export default Waiver;
