import React from "react";
import { AccountSummaryRankType, AccountSummaryCardTheme } from "components/AccountSummaryData";

function AccountSummaryCoopCard({ number, type, theme, accountSummaryData, cardsData }) {
   return (
      <>
         {type === AccountSummaryRankType.Previous && <PreviousCard number={number} />}
         {type === AccountSummaryRankType.Current && (
            <CurrentCard number={number} theme={theme} cardsData={cardsData} accountSummaryData={accountSummaryData} />
         )}
         {type === AccountSummaryRankType.Next && (
            <NextCard number={number} theme={theme} cardsData={cardsData} accountSummaryData={accountSummaryData} />
         )}
      </>
   );
}

function PreviousCard({ number }) {
   return (
      <div className="a_single_a" style={{ backgroundImage: AccountSummaryCardTheme.Grey.backgroundImageCard }}>
         <h3>PREVIOUS RANK:</h3>
         <div className="a_single_top">
            <div className="ac_con" style={{ backgroundImage: AccountSummaryCardTheme.Grey.backgroundImageNumber }}>
               {number}
            </div>
         </div>
         <div className="p_color p_color_previous">
            <p className="mb-0">YOU'VE UNLOCKED A PRIZE:</p>
            <p className="mb-0" style={{ color: `${AccountSummaryCardTheme.Grey.rewardTextColor}` }}>
               ENERGIZE WATER BOTTLE
            </p>
         </div>
      </div>
   );
}

function CurrentCard({ number, theme, cardsData, accountSummaryData }) {
   return (
      <div className="a_single_a a_single_a_color" style={{ backgroundImage: theme?.backgroundImageCard }}>
         <h3>CURRENT RANK:</h3>
         <div className="a_single_top">
            <div className="ac_con" style={{ backgroundImage: theme?.backgroundImageNumber }}>
               {number}
            </div>
            <div className="a_right">
               <div className="ar_icon">
                  <img src={require("assets/img/checkscore/logo1v2.webp")} alt="" />
               </div>
               <div className="ar_text">
                  <p className="mb-0" style={{ color: "#00E646" }}>
                     Energize <br /> Tokens
                  </p>
                  <h2>{accountSummaryData?.user?.energizeToken}</h2>
               </div>
            </div>
         </div>
         <div className="p_color p_color_current">
            <p className="mb-0">YOU'VE UNLOCKED A PRIZE:</p>
            <p className="mb-0 text-uppercase" style={{ color: `${AccountSummaryCardTheme.Violet.rewardTextColor}` }}>
               {cardsData?.currentRank?.prize}
            </p>
            <a className="mt-1">Book your next visit and claim!</a>
         </div>
      </div>
   );
}

function NextCard({ number, theme, cardsData, accountSummaryData }) {
   return (
      <div className="a_single_a a_single_a_color_next" style={{ backgroundImage: theme?.backgroundImageCard }}>
         <h3>NEXT RANK:</h3>
         <div className="a_single_top">
            <div className="ac_con" style={{ backgroundImage: theme?.backgroundImageNumber }}>
               {number}
            </div>
            <div className="a_right" style={{ justifyContent: "flex-end" }}>
               <div className="ar_text">
                  <h2>
                     <img src={theme?.lockIcon} alt="" />
                     {cardsData?.nextRank?.tokensneeded - accountSummaryData?.user?.energizeToken}
                  </h2>
                  <p style={{ color: "#FFF" }}>
                     Energize <br /> Tokens
                     <br />
                     To Go
                  </p>
               </div>
            </div>
         </div>
         <div className="p_color p_color_next">
            <p className="mb-0">NEXT PRIZE:</p>
            <p className="mb-0 text-uppercase" style={{ color: `${theme?.rewardTextColor}` }}>
               {cardsData?.nextRank?.prize}
            </p>
            <a className="mt-1 test">
               <p className="earn-more-tokens-text">Earn more Energize Tokens!</p>
            </a>
         </div>
      </div>
   );
}

export default AccountSummaryCoopCard;
