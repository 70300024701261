import React, { useCallback, useEffect, useState } from "react";
import Bracelet from "../assets/img/Scan_bracelet.webp";
import { Link, useNavigate } from "react-router-dom";
import authService from "../api/auth.service";
import { DeviceSettings } from "constants/api.constants";
import selectionScreenApi from "api/selection.screen";
import ImgBack from "assets/img/Tablet_screen_Back_Btn.webp";
import AuthService from "../api/auth.service";
import authApi from "../api/auth.service";

const ScanBracelet = props => {
   const [currentCardUid, setCurrentCardUid] = useState("");
   const navigate = useNavigate();

   const getCardInfoCallback = useCallback(async cardUid => {
      try {
         const cardInfoResult = await authService.getCardInfo(cardUid);
         const asignCardToUser = await authService.setCardToUser(cardInfoResult.data.data[0].id);

         if (asignCardToUser.data.status.code === 0) {
            navigate("/game-on");
         }
      } catch (e) {
         navigate("/error");
      }
   }, []);

   useEffect(() => {
      const scanBraceletInterval = setInterval(async () => {
         const responseFromScan = await selectionScreenApi.scanBraceletTroughAPI();
         const idBracelet = responseFromScan?.id;

         if (responseFromScan !== "Not found.") setCurrentCardUid(responseFromScan?.data);

         if (idBracelet && currentCardUid && currentCardUid !== undefined && currentCardUid !== "Not found.") {
            const isDeletedFromScan = await selectionScreenApi.deleteBraceletTroughAPI(idBracelet);
            if (isDeletedFromScan === 0) setCurrentCardUid("");
            getCardInfoCallback(responseFromScan?.data);
         }
      }, DeviceSettings.scanInterval);

      return () => clearInterval(scanBraceletInterval);

   }, [currentCardUid]);
//
   return (
      <div className="tablet-container">
         <div>
            <Link to={"/login"}>
               <img className={"button-back-scan"} src={ImgBack} />
            </Link>
         </div>
         <img className={"w-100"} src={Bracelet} alt={"Scan Your Bracelet"} />
      </div>
   );
};

export default ScanBracelet;
