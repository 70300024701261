import axios from "axios";
import { API_URL, LOCAL_API_URL } from "constants/api.constants";
import AuthService from "api/auth.service";
import { toast } from "react-toastify";

export const RequestMethod = {
   GET: "Get",
   POST: "Post",
   PUT: "Put",
   DELETE: "Delete",
};

export async function requestJson(path, method, options) {
   try {
      const response = await executeRequest(API_URL, path, method, options);
      return { response: response, isSuccess: true, error: null };
   } catch (ex) {
      console.error(ex?.message ?? ex);
      toast.error(ex?.message ?? ex);
      return { response: null, isSuccess: false, error: ex?.message ?? ex };
   }
}

export async function requestJsonLocalServer(path, method, options) {
   try {
      const response = await executeRequest(LOCAL_API_URL, path, method, options);
      return { response: response, isSuccess: true, error: null };
   } catch (ex) {
      console.error(ex?.message ?? ex);
      // commented this function because setLeds was always returning an "Network Error" (it didn't matter if it was success or not) from backend and I didn't want to show it to the user
      // (i could have created an if statement to show the toast.err but i don't know the error code when it is working (response is success but it still returns an error maybe a different one))
      // toast.error(ex?.message ?? ex);
      return { response: null, isSuccess: false, error: ex?.message ?? ex };
   }
}

async function executeRequest(baseUrl, path, method, options) {
   const url = baseUrl + path;
   const headers = getHeaders(options);
   let response;
   switch (method) {
      case RequestMethod.GET:
         response = await axios.get(jsonToUrl(url, options?.queryStringParams), { headers: headers });
         break;
      case RequestMethod.POST:
         response = await axios.post(jsonToUrl(url, options?.queryStringParams), options?.data, {
            headers: headers,
         });
         break;
      case RequestMethod.PUT:
         response = await axios.put(jsonToUrl(url, options?.queryStringParams), options?.data, {
            headers: headers,
         });
         break;
      case RequestMethod.DELETE:
         response = await axios.delete(jsonToUrl(url, options?.queryStringParams), { headers: headers });
         break;
   }

   return response?.data;
}

function getHeaders(options) {
   let headers = {};

   if (options?.useAccessToken) {
      const user = AuthService.getCurrentUser();
      headers["Authorization"] = `Bearer ${user.token}`;
   }

   if (options?.headers) {
      options.headers.forEach(header => {
         headers[header.name] = header.value;
      });
   }

   return headers;
}

function jsonToUrl(url, data) {
   if (data) {
      const urlData = Object.keys(data)
         .map(k => {
            if (data[k] === undefined) return;
            if (Array.isArray(data[k])) {
               const result = data[k].map(x => encodeURIComponent(k) + "=" + encodeURIComponent(x)).join("&");
               return result;
            }
            return encodeURIComponent(k) + "=" + encodeURIComponent(data[k]);
         })
         .join("&");

      if (urlData === undefined && urlData === "") return url;
      return `${url}?${urlData}`;
   }

   return url;
}
