import React from "react";
import FinBtn from "../assets/img/Finalize.webp";
import RegTxt from "../assets/img/Registration Confirmed.webp";
import { useNavigate, useLocation } from "react-router-dom";
import AuthService from "../api/auth.service";

const RegistrationConfirmed = () => {
   const navigate = useNavigate();
   const { state } = useLocation();
   const { nickname, password } = state || {};

   const handleSubmit = () => {
      AuthService.login(nickname, password).then(
         response => {
            if (response.data.status.code === 0) {
               navigate("/scan");
            } else {
               navigate("/error");
            }
         },
         error => {
            const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            // setLoading(false);
            // setMessage(resMessage);
            // console.log(resMessage)
         },
      );
   };

   return (
      <div className="tablet-container reg-confirm">
         <div className="w-80 m-auto">
            <img className="w-img" src={RegTxt} />
            <p className="link text-decoration-none text-center my12">Remember to confirm you email address later</p>
            <img className="w-img" onClick={handleSubmit} src={FinBtn} />
         </div>
      </div>
   );
};

export default RegistrationConfirmed;
