import { CooperativeGameDisplayType, GameTemplates, GameTypes } from "constants/games.constants";
import { ActiveGameStatus } from "constants/api.constants";

export const createCooperativeRunningGame = (screentemplate, status = ActiveGameStatus.RUNNING) => {
   const gameTemplate = GameTemplates.find(x => x.id.toLocaleLowerCase() === screentemplate.toLocaleLowerCase());

   return {
      status: { code: 0, description: "OK", details: "" },
      data: [
         {
            runninggameid: 22,
            gameid: 1,
            gametype: gameTemplate.gameType,
            name: "some name",
            screentemplate: screentemplate,
            levelid: {
               id: 1,
               level: 1,
               params: '{"param1": 135,"param2": 15,"param3": 10}',
               description: "Descriere nivel 1",
               goal: 15,
               playtime: 180,
               waitstarttime: 5,
            },
            nextlevelid: {
               id: 2,
               level: 2,
               params: '{"param1": 135,"param2": 15,"param3": 10}',
               description: "Descriere nivel 2",
               goal: 20,
               playtime: 180,
               waitstarttime: 5,
            },
            activetime: "2022-10-22T17:29:47.87676",
            starttime: null,
            status: status,
            hearts: 5,
            goal: 15,
            playtime: 180,
            waitstarttime: 5,
            cooperativescore: gameTemplate.gameType === GameTypes.Cooperative ? 5 : null,
            usersList: [
               {
                  userid: 8,
                  nickname: "alin44",
                  colorid: 0,
                  colorname: "red",
                  competitivescore: gameTemplate.gameType === GameTypes.Cooperative ? null : 12,
                  remainingtime: 70,
               },
               {
                  userid: 8,
                  nickname: "bogdan55",
                  colorid: 0,
                  colorname: "green",
                  competitivescore: gameTemplate.gameType === GameTypes.Cooperative ? null : 5,
                  remainingtime: 110,
               },
            ],
            questionid: gameTemplate.gameDisplayType === CooperativeGameDisplayType.QUESTION ? 1 : null,
            colorpatternid: {
               patternList: [
                  { colorid: { name: "red" } },
                  { colorid: { name: "blue" } },
                  { colorid: { name: "green" } },
                  { colorid: { name: "orange" } },
                  { colorid: { name: "violet" } },
               ],
            },
         },
      ],
   };
};

export const createCompetitiveRunningGame = (screentemplate, status = ActiveGameStatus.RUNNING, isDraw = false) => {
   return {
      status: {
         code: 0,
         description: "OK",
         details: "",
      },
      data: [
         {
            runninggameid: 948,
            gameid: 13,
            gametype: "COMPETITIVE",
            competitivetype: "Single",
            idaudio: 20,
            name: "name",
            screentemplate: screentemplate,
            levelid: {
               id: 121,
               level: 1,
               params: '{"param1": "10,0,25","param2": 15,"param3": 10}',
               description: "",
               goal: 1,
               playtime: 60,
               waitstarttime: 5,
            },
            nextlevelid: {
               id: 122,
               level: 2,
               params: '{"param1": "10,0,0","param2": 15,"param3": 10}',
               description: "",
               goal: 1,
               playtime: 180,
               waitstarttime: 5,
            },
            activetime: "2023-03-28T10:07:51.426715",
            starttime: "2023-03-28T10:07:58.236292",
            status: status,
            hearts: 5,
            goal: 1,
            playtime: 60,
            waitstarttime: 5,
            cooperativescore: null,
            usersList: [
               {
                  userid: 1,
                  nickname: "luci",
                  colorid: 33,
                  colorname: "red",
                  rgbval: "#FF0000",
                  competitivescore: isDraw ? 100 : 2700,
                  remainingtime: null,
               },
               {
                  userid: 2,
                  nickname: "luci1",
                  colorid: 34,
                  colorname: "green",
                  rgbval: "#00FF00",
                  competitivescore: isDraw ? 100 : 2500,
                  remainingtime: null,
               },
               {
                  userid: 3,
                  nickname: "luci2",
                  colorid: 35,
                  colorname: "blue",
                  rgbval: "#0000FF",
                  competitivescore: isDraw ? 100 : 2000,
                  remainingtime: null,
               },
               {
                  userid: 4,
                  nickname: "luci3",
                  colorid: 36,
                  colorname: "violet",
                  rgbval: "#6B354D",
                  competitivescore: isDraw ? 100 : 2000,
                  remainingtime: null,
               },
               {
                  userid: 5,
                  nickname: "luci4",
                  colorid: 37,
                  colorname: "orange",
                  rgbval: "#F3601F",
                  competitivescore: isDraw ? 100 : 1500,
                  remainingtime: null,
               },
            ],
            questionid: null,
            colorpatternid: null,
            winpoints: null,
         },
      ],
   };
};

export const TestGames = {
   cooperativeGames: {
      countdownGameRunning: createCooperativeRunningGame("countdownHearts"),
      countdownGameWaitingStart: createCooperativeRunningGame("countdownHearts", ActiveGameStatus.WAITING_START),
      countdownGameFailed: createCooperativeRunningGame("countdownHearts", ActiveGameStatus.COMPLETE_FAILURE),
      countdownGameCompleted: createCooperativeRunningGame("countdownHearts", ActiveGameStatus.COMPLETE_SUCCESS),

      countdownBulletsGameRunning: createCooperativeRunningGame("countdownBullets"),
      countdownBulletsGameWaitingStart: createCooperativeRunningGame("countdownBullets", ActiveGameStatus.WAITING_START),
      countdownBulletsGameFailed: createCooperativeRunningGame("countdownBullets", ActiveGameStatus.COMPLETE_FAILURE),
      countdownBulletsGameCompleted: createCooperativeRunningGame("countdownBullets", ActiveGameStatus.COMPLETE_SUCCESS),

      countdownQuestionGameRunning: createCooperativeRunningGame("countdownQuestion"),
      countdownQuestionGameWaitingStart: createCooperativeRunningGame("countdownQuestion", ActiveGameStatus.WAITING_START),
      countdownQuestionGameFailed: createCooperativeRunningGame("countdownQuestion", ActiveGameStatus.COMPLETE_FAILURE),
      countdownQuestionGameCompleted: createCooperativeRunningGame("countdownQuestion", ActiveGameStatus.COMPLETE_SUCCESS),
   },
   competitiveGames: {
      countdownElapseGameRunning: createCompetitiveRunningGame("countdownElapse"),
      countdownElapseGameWaitingStart: createCompetitiveRunningGame("countdownElapse", ActiveGameStatus.WAITING_START),
      countdownElapseGameFailed: createCompetitiveRunningGame("countdownElapse", ActiveGameStatus.COMPLETE_FAILURE),
      countdownElapseGameWinner: createCompetitiveRunningGame("countdownElapse", ActiveGameStatus.COMPLETE_SUCCESS),
      countdownElapseGameDraw: createCompetitiveRunningGame("countdownElapse", ActiveGameStatus.COMPLETE_SUCCESS, true),

      countdownTeamScoreRunning: createCompetitiveRunningGame("countdownTeamScore"),
      countdownTeamScoreWaitingStart: createCompetitiveRunningGame("countdownTeamScore", ActiveGameStatus.WAITING_START),
      countdownTeamScoreFailed: createCompetitiveRunningGame("countdownTeamScore", ActiveGameStatus.COMPLETE_FAILURE),
      countdownTeamScoreWinner: createCompetitiveRunningGame("countdownTeamScore", ActiveGameStatus.COMPLETE_SUCCESS),
      countdownTeamScoreDraw: createCompetitiveRunningGame("countdownTeamScore", ActiveGameStatus.COMPLETE_SUCCESS, true),
   },
};
