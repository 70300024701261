import React from "react";
import GameOnImg from "../assets/img/game_on.webp";
import { useNavigate } from "react-router-dom";


const GameOn = () => {
   const navigate = useNavigate();

   setTimeout(() => {
      navigate("/");
   }, 5000);

   return (<div className="tablet-container" onClick={() => {
      navigate("/");
   }}>
      <img className={"w-100"} src={GameOnImg} alt={"Game on"} />
   </div>);
};

export default GameOn;
