import { requestJsonLocalServer, RequestMethod } from "helpers/httpHelper";

export const setLeds = async number => {
   const result = await requestJsonLocalServer(`leds/${number}`, RequestMethod.POST, {
      useAccessToken: false,
   });

   return result;
};

export const openDoor = async () => {
   const result = await requestJsonLocalServer(`el/on`, RequestMethod.POST, {
      useAccessToken: false,
   });

   return result;
};
