import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import selectionScreenApi from "api/selection.screen";
import CompetitiveGame from "./Games/CompetitiveGame";
import CooperativeLevelCompleted from "./Games/CooperativeLevelCompleted";
import CompetitiveLevelCompleted from "./Games/CompetitiveLevelCompleted";
import CooperativeGame from "./Games/CooperativeGame";
import { GameTypes, GameTemplates } from "constants/games.constants";
import { ActiveGameStatus, ApiRetryTimeout, OkResultStatusCode } from "constants/api.constants";

import TopScore from "./TopScore/TopScore";

export default function TvScreenOld() {
   const { roomId } = useParams();
   const [activeGameId, setActiveGameId] = useState(null);
   const [remainingTime, setRemainingTime] = useState(0);
   const [activeGameStatus, setActiveGameStatus] = useState(null);
   const [highestScores, setHighestScores] = useState([]);

   const getActiveGame = async () => {
      const runningGameResult = await selectionScreenApi.getActiveGame(roomId);
      if (
         runningGameResult.isSuccess &&
         (runningGameResult.response.status.code === OkResultStatusCode || runningGameResult.response.status.description === "OK") &&
         runningGameResult.response.data[0].status !== ActiveGameStatus.WAITING_ROOM
      ) {
         setActiveGameId(runningGameResult.response.data[0].runninggameid);
      } else {
         setActiveGameId(null);
         setTimeout(getActiveGame, ApiRetryTimeout);
      }
   };

   const getActiveGameStatus = async () => {
      const runningGameStatusResult = await selectionScreenApi.getGameStatus(roomId, activeGameId);
      if (
         runningGameStatusResult.isSuccess &&
         (runningGameStatusResult.response.status.code === OkResultStatusCode || runningGameStatusResult.response.status.description === "OK")
      ) {
         setActiveGameStatus(runningGameStatusResult.response.data[0]);

         const runningGameStatus = runningGameStatusResult.response.data[0].status;
         if (runningGameStatus === ActiveGameStatus.WAITING_START || runningGameStatus === ActiveGameStatus.RUNNING) {
            setTimeout(getActiveGameStatus, ApiRetryTimeout);
         } else {
            setActiveGameId(null);
         }
      } else {
         getActiveGame();
      }
   };

   const getHighestScores = async () => {
      const highestScoresResult = await selectionScreenApi.getTopHighestScores();

      if (highestScoresResult.isSuccess) {
         setHighestScores(highestScoresResult.response.data);
      }
   };

   const getScreenContainerClass = () => {
      let baseClass = "screen-container";
      if (activeGameStatus?.gametype === GameTypes.Competitive) return baseClass;

      if (activeGameStatus?.status === ActiveGameStatus.COMPLETE_FAILURE) return `${baseClass} bg-screen-red`;
      if (activeGameStatus?.status === ActiveGameStatus.COMPLETE_SUCCESS) return `${baseClass} bg-screen-green`;

      return baseClass;
   };

   useEffect(() => {
      getHighestScores();

      if (activeGameId === null) {
         getActiveGame();
      } else {
         getActiveGameStatus();
      }
   }, [activeGameId]);

   const gameTemplate = GameTemplates.find(x => x.id.toLowerCase() === activeGameStatus?.screentemplate?.toLowerCase());
   if (gameTemplate === undefined && activeGameStatus?.id !== undefined) {
      toast.error(`Game with screen template ${activeGameStatus?.screentemplate} not found`);
   }
   const isRunningGameCompleted =
      activeGameStatus?.status === ActiveGameStatus.COMPLETE_FAILURE || activeGameStatus?.status === ActiveGameStatus.COMPLETE_SUCCESS;

   return (
      <div className={getScreenContainerClass()}>
         <div className={`tv-slider ${activeGameStatus?.gametype === GameTypes.Competitive ? "red-template" : "green-template"} full-slider`}>
            {!isRunningGameCompleted && activeGameStatus?.gametype === GameTypes.Competitive && (
               <CompetitiveGame
                  gameDisplayType={gameTemplate?.gameDisplayType}
                  runningGameResult={activeGameStatus}
                  isPlaying={activeGameStatus.status === ActiveGameStatus.RUNNING}
                  key={activeGameStatus.status}
                  setRemainingTime={setRemainingTime}
               />
            )}
            {!isRunningGameCompleted && activeGameStatus?.gametype === GameTypes.Cooperative && (
               <CooperativeGame
                  gameDisplayType={gameTemplate?.gameDisplayType}
                  showHearts={gameTemplate?.showHearts}
                  runningGameResult={activeGameStatus}
                  isPlaying={activeGameStatus.status === ActiveGameStatus.RUNNING}
                  key={activeGameStatus.status}
                  setRemainingTime={setRemainingTime}
               />
            )}
            {isRunningGameCompleted && activeGameStatus?.gametype === GameTypes.Competitive && (
               <CompetitiveLevelCompleted runningGameResult={activeGameStatus} />
            )}
            {isRunningGameCompleted && activeGameStatus?.gametype === GameTypes.Cooperative && (
               <CooperativeLevelCompleted gameTemplate={gameTemplate} runningGameResult={activeGameStatus} remainingTime={remainingTime} />
            )}
            {activeGameId === null && activeGameStatus === null && <TopScore highestScores={highestScores} />}
         </div>
      </div>
   );
}
