import React from "react";
import imgLevelDraw from "assets/img/Its_a_draw.webp";
import imgWins from "assets/img/WINS.webp";

export default function CompetitiveLevelCompleted({ runningGameResult }) {
   const maxScore = Math.max(...runningGameResult.usersList.map(x => x.competitivescore));
   const usersWithMaxScore = runningGameResult.usersList.filter(x => x.competitivescore === maxScore);

   return (
      <React.Fragment>
         {usersWithMaxScore.length === 1 ? <CompetitivePlayerWon player={usersWithMaxScore[0].nickname} /> :
            <CompetitiveDraw />}
      </React.Fragment>
   );
}

const CompetitivePlayerWon = ({ player }) => {
   return (
      <div className="w-100">
         <h1
            style={{
               fontSize: 80,
               color: "white",
               textAlign: "center",
               margin: "auto",
               width: "50%",
            }}
         >
            {player}
         </h1>
         <img className={"w-50 d-block m-auto"} src={imgWins} />
      </div>
   );
};

const CompetitiveDraw = () => {
   return (
      <div className="w-100">
         <img className={"w-100"} src={imgLevelDraw} />
      </div>
   );
};
