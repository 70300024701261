import React, { useEffect, useState } from "react";
import SimpleCountDown from "components/Sliders/SimpleCountDown";
import Heart from "assets/img/Heart_empty.webp";
import imgLevelFailed from "assets/img/Level_Failed.webp";
import imgLevelCompleted from "assets/img/Level_Completed.webp";
import imgEarned from "assets/img/You_Have_Earned.webp";
import { ActiveGameStatus } from "constants/api.constants";

const CompletedScreenTimeout = localStorage.getItem("CompletedScreenTimeout") ?? 5000;

export default function CooperativeLevelCompleted({ gameTemplate, runningGameResult, remainingTime }) {
   return (
      <React.Fragment>
         {runningGameResult?.status === ActiveGameStatus.COMPLETE_FAILURE && (
            <LevelFailed runningGameResult={runningGameResult} gameTemplate={gameTemplate} remainingTime={remainingTime} />
         )}
         {runningGameResult?.status === ActiveGameStatus.COMPLETE_SUCCESS && <LevelCompleted pointsEarned={runningGameResult?.winpoints ?? 0} />}
      </React.Fragment>
   );
}

const LevelFailed = ({ runningGameResult, gameTemplate, remainingTime }) => {
   const [type, setType] = useState(0);

   useEffect(() => {
      setTimeout(() => {
         setType(1);
      }, CompletedScreenTimeout);
   }, []);

   return (
      <React.Fragment>
         {type === 0 && (
            <div className="w-100">
               <img className={"w-50 d-block m-auto"} src={imgLevelFailed} />
            </div>
         )}
         {type === 1 && (
            <div className="container">
               {gameTemplate.showHearts && (
                  <div className="row row-heart">
                     <div className="col-md-12">
                        {[...Array(5)].map((x, key) => (
                           <img key={key} src={Heart} />
                        ))}
                     </div>
                  </div>
               )}

               <div className="row pl8 pr8">
                  <div className="col-md-6 progress-bar-box">
                     <SimpleCountDown
                        duration={runningGameResult.playtime}
                        startFrom={remainingTime}
                        isPlaying={false}
                        setRemainingTime={undefined}
                     />
                  </div>
                  <div className="col-md-6 d-flex align-items-center">
                     <div className="w-100">
                        <img className={"w-100 d-block m-auto"} src={imgLevelFailed} />
                     </div>
                  </div>
               </div>
            </div>
         )}
      </React.Fragment>
   );
};

const LevelCompleted = ({ pointsEarned }) => {
   const [type, setType] = useState(0);

   useEffect(() => {
      setTimeout(() => {
         setType(1);
      }, CompletedScreenTimeout);
   }, []);

   return (
      <React.Fragment>
         {type === 0 && (
            <div className="w-100">
               <img className={"w-100"} src={imgLevelCompleted} />
            </div>
         )}
         {type === 1 && (
            <div className="w-100">
               <img className={"w-50 d-block m-auto"} src={imgEarned} />
               <h1
                  style={{
                     fontSize: 80,
                     color: "white",
                     textAlign: "center",
                     margin: "auto",
                     width: "50%",
                  }}
               >
                  {pointsEarned} points
               </h1>
            </div>
         )}
      </React.Fragment>
   );
};
