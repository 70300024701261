import React from "react";


const TableSelectionScreenCompetitive = ({ players, colors }) => {

   return (<table className={"m1"}>
      <thead>
      <tr>
         <td width={"33%"}>Player <br /> Name</td>
         <td width={"15%"}>Time <br /> Left</td>
         <td width={"23%"}>Total Wins <br /> Today</td>
         <td width={"29%"}>Current Game <br /> Wins Today</td>
      </tr>
      <tr height={"15px"}></tr>
      </thead>
      <tbody>
      {players.map((elem, index) => (
         <tr key={index} className={"tr-table"} style={{ backgroundColor: colors[index]?.rgbval }}>
            <td>{elem?.userData?.userid?.nickname}</td>
            <td>{elem?.timeLeft?.secondsRemains}</td>
            <td>{elem?.highUserScore}</td>
            <td>{elem?.tokenReward}</td>
         </tr>))}
      </tbody>
   </table>);
};

export default TableSelectionScreenCompetitive;
