import React from "react";
import SimpleCountDown from "components/Sliders/SimpleCountDown";

const TvScreenGameStart = props => {
   return (
      <div className="screen-container ">
         {/*red-template in loc de green-template (pentru slider rosu) */}
         <div className="tv-slider green-template full-slider">
            <div className="container">
               <SimpleCountDown />
            </div>
         </div>
      </div>
   );
};

export default TvScreenGameStart;
