import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import PinBtn from "../assets/img/Generate_PIN.webp";
import AuthService from "../api/auth.service";
import { Link, useNavigate } from "react-router-dom";
import Border from "../components/Border";
import LogBtn from "../assets/img/Log_in.webp";
import ImgBack from "../assets/img/Tablet_screen_Back_Btn.webp";

const LostBracelet = props => {
   const {
      register,
      handleSubmit,
      formState: { errors },
   } = useForm({ mode: "onSubmit" });

   const navigate = useNavigate();
   const onSubmit = data => {
      AuthService.login(data.nickName, data.password).then(
         response => {
            if (response.data.status.code === 0) {
               let user = AuthService.getCurrentUser();
               AuthService.resetCard(user).then(responseCard => {
                  // console.log(responseCard);
               });
            } else {
               navigate("/error");
            }
         },
         error => {
         },
      );
   };
   return (
      <div className="main-container">
         <Link to={"/login"}>
            <img className={"button-back button-back-lostBracelet"} src={ImgBack} />
         </Link>
         <p className={"default-text mb3"}>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Hic, repellendus sit. Asperiores assumenda atque
            consequuntur culpa cum dolorem,
            doloribus, ea esse eveniet facere illo ipsa modi nam quam rem repellendus reprehenderit totam velit vitae
            voluptates? Hic inventore non
            praesentium suscipit!
         </p>
         <form>
            <div className="form-group">
               <Border />
               <input
                  autoComplete="new-password"
                  placeholder="nickname"
                  {...register("nickName", {
                     required: "Please fill in this field correctly!",
                  })}
                  className={`${errors.nickName ? "is-invalid" : "is-valid"}`}
               />
               <br />
               <p className={"error-message"}> {errors.nickName && errors.nickName.message} </p>
               <br />
            </div>
            <div className="form-group">
               <Border />
               <input
                  autoComplete="new-password"
                  className={`${errors.nickName ? "is-invalid" : "is-valid"}`}
                  placeholder="password"
                  type="password"
                  {...register("password", {
                     required: "Please fill in this field correctly!",
                     minLength: {
                        value: 2,
                        message: "Minimum length is 4",
                     },
                  })}
               />
               <br />
               <p className={"error-message"}> {errors.password && errors.password.message}</p>
               <br />
            </div>
            <div className="mt-22"></div>

            <div className="form-group">
               <input type="image" className="w-img bg-transparent" src={PinBtn} onClick={handleSubmit(onSubmit)} />
            </div>
         </form>
      </div>
   );
};

export default LostBracelet;
