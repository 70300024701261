const firstUserList = [
   {
      timeLeft: {
         secondsRemains: 1800,
      },
      highUserScore: 0,
      tokenUser: 0,
      tokenReward: 0,
      highScoreToday: 0,
      highScore90Days: 0,
      highScoreAllTime: 0,
      playerLevelId: 6,
      userData: {
         timeslot: 180000,
         uid: "4028151300",
         userid: {
            id: 7,
            email: "",
            nickname: "razvan",
         },
      },
   },
   {
      timeLeft: {
         secondsRemains: 1800,
      },
      highUserScore: 0,
      tokenUser: 0,
      tokenReward: 0,
      highScoreToday: 0,
      highScore90Days: 0,
      highScoreAllTime: 0,
      playerLevelId: 3,
      userData: {
         timeslot: 180000,
         uid: "4028250148",
         userid: {
            id: 8,
            email: "",
            nickname: "alin33",
         },
      },
   },
   {},
   {},
   {},
];
const secondUserList = [
   {
      timeLeft: {
         secondsRemains: 1800,
      },
      highUserScore: 0,
      tokenUser: 0,
      tokenReward: 0,
      highScoreToday: 0,
      highScore90Days: 0,
      highScoreAllTime: 0,
      playerLevelId: 6,
      userData: {
         timeslot: 180000,
         uid: "4028250148",
         userid: {
            id: 5,
            email: "",
            nickname: "alin33",
         },
      },
   },
   {
      timeLeft: {
         secondsRemains: 1800,
      },
      highUserScore: 0,
      tokenUser: 0,
      tokenReward: 0,
      highScoreToday: 0,
      highScore90Days: 0,
      highScoreAllTime: 0,
      playerLevelId: 4,
      userData: {
         timeslot: 180000,
         uid: "4028151300",
         userid: {
            id: 9,
            email: "",
            nickname: "razvan",
         },
      },
   },
   {},
   {},
   {},
];

export { firstUserList, secondUserList };
