import React from "react";
import { buildStyles, CircularProgressbarWithChildren } from "react-circular-progressbar";

const greenTemplateProps = buildStyles({
   trailColor: "#00E646",
   pathColor: "#CFF3B1",
   pathTransitionDuration: 1,
});

const redTemplateProps = buildStyles({
   trailColor: "#FE0000",
   pathColor: "#FFBFBF",
   pathTransitionDuration: 1,
});

const blueTemplateProps = buildStyles({
   trailColor: "#0055ff",
   pathColor: "#CFF3B1",
   pathTransitionDuration: 1,
});

const violetTemplateProps = buildStyles({
   trailColor: "#6B354D",
   pathColor: "#CFF3B1",
   pathTransitionDuration: 1,
});

const orangeTemplateProps = buildStyles({
   trailColor: "#fe8f00",
   pathColor: "#CFF3B1",
   pathTransitionDuration: 1,
});

const purpleTemplateProps = buildStyles({
   trailColor: "#850085",
   pathColor: "#CFF3B1",
   pathTransitionDuration: 1,
});

export default function SimpleCountDown({
   duration = 180,
   startFrom = 180,
   size = "normal",
   template = "green",
   timeClassNames = undefined,
   setRemainingTime = undefined,
   isPlaying = true,
   imgGo = undefined,
   displayCounter = undefined,
}) {
   const [counter, setCounter] = React.useState(startFrom ?? 0);
   React.useEffect(() => {
      const timer =
         isPlaying &&
         counter &&
         setInterval(() => {
            const newCounter = counter - 1;
            setCounter(newCounter);
            setRemainingTime && setRemainingTime(newCounter);
         }, 1000);
      return () => clearInterval(timer);
   }, [counter, isPlaying]);

   const getTemplateStyles = template => {
      switch (template) {
         case "red":
            return redTemplateProps;
         case "purple":
            return purpleTemplateProps;
         case "blue":
            return blueTemplateProps;
         case "violet":
            return violetTemplateProps;
         case "orange":
            return orangeTemplateProps;
         default:
            return greenTemplateProps;
      }
   };

   const progress = (duration - (displayCounter ?? counter)) / duration;
   return (
      <CircularProgressbarWithChildren styles={getTemplateStyles(template)} value={progress * 100} strokeWidth={size === "small" ? 7 : 4}>
         {counter === 0 && imgGo !== undefined ? (
            <div className="w-100">
               <img className={"w-100"} src={imgGo} />
            </div>
         ) : (
            <span className={`time-counter ${size === "small" ? "font1" : ""}`}>
               <div className="time-wrapper">
                  <div className={`time ${timeClassNames !== undefined ? timeClassNames : ""}`}>{getTimeSeconds(displayCounter ?? counter)}</div>
               </div>
            </span>
         )}
      </CircularProgressbarWithChildren>
   );
}

const padTime = time => {
   return String(time).split(".")[0].length === 1 ? `0${time}` : `${time}`;
};

const getTimeSeconds = time => {
   if (time < 0) {
      time = 0;
   }
   // Convert seconds into minutes and take the whole part
   const minutes = Math.floor(time / 60);

   // Get the seconds left after converting minutes
   const seconds = time % 60;

   let finalTime = padTime(minutes) + ":" + padTime(seconds);

   //Return combined values as string in format mm:ss
   return finalTime.split(".")[0];
};
