import React from "react";

const SelectionScreenMessage = ({imgSrc}) => {

    return (<div className="screen-container">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <img className={"w-100"} src={imgSrc}/>
                </div>
            </div>
        </div>
    </div>);
};

export default SelectionScreenMessage;
