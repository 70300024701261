import React, { useState } from "react";
import { TestGames } from "pages/TvScreen/__test__/testdata";
import CompetitiveGame from "pages/TvScreen/Games/CompetitiveGame";
import CooperativeLevelCompleted from "pages/TvScreen/Games/CooperativeLevelCompleted";
import CompetitiveLevelCompleted from "pages/TvScreen/Games/CompetitiveLevelCompleted";
import CooperativeGame from "pages/TvScreen/Games/CooperativeGame";
import { GameTypes, GameTemplates } from "constants/games.constants";
import { ActiveGameStatus } from "constants/api.constants";

export default function TvScreenTest() {
   const [remainingTime, setRemainingTime] = useState(67);

   // COOPERATIVE

   // Cooperative - Countdown Waiting start
   // const activeGameStatus = TestGames.cooperativeGames.countdownGameWaitingStart.data[0];

   // Cooperative - Countdown Running
   // const activeGameStatus = TestGames.cooperativeGames.countdownGameRunning.data[0];

   // Cooperative - Countdown Failed
   // const activeGameStatus = TestGames.cooperativeGames.countdownGameFailed.data[0];

   // Cooperative - Bullets Waiting start
   // const activeGameStatus = TestGames.cooperativeGames.countdownBulletsGameWaitingStart.data[0];

   // Cooperative - Bullets Running
   // const activeGameStatus = TestGames.cooperativeGames.countdownBulletsGameRunning.data[0];

   // Cooperative - Question Running
   // const activeGameStatus = TestGames.cooperativeGames.countdownQuestionGameRunning.data[0];

   // COMPETITIVE

   // Competitive - Countdown Elapse Waiting start
   // const activeGameStatus = TestGames.competitiveGames.countdownElapseGameWaitingStart.data[0];

   // Competitive - Countdown Elapse Running
   // const activeGameStatus = TestGames.competitiveGames.countdownElapseGameRunning.data[0];

   // Competitive - Countdown TeamScore Waiting start
   // const activeGameStatus = TestGames.competitiveGames.countdownTeamScoreWaitingStart.data[0];

   // Competitive - Countdown TeamScore Running
   const activeGameStatus = TestGames.competitiveGames.countdownTeamScoreRunning.data[0];

   // Competitive - Countdown TeamScore WINNER
   // const activeGameStatus = TestGames.competitiveGames.countdownTeamScoreWinner.data[0];

   // Competitive - Countdown TeamScore DRAW
   // const activeGameStatus = TestGames.competitiveGames.countdownTeamScoreDraw.data[0];

   // Competitive - Countdown TeamScore FAILED
   // const activeGameStatus = TestGames.competitiveGames.countdownTeamScoreFailed.data[0];

   const gameTemplate = GameTemplates.find(x => x.id.toLocaleLowerCase() === activeGameStatus.screentemplate.toLocaleLowerCase());
   const isRunningGameCompleted =
      activeGameStatus?.status === ActiveGameStatus.COMPLETE_FAILURE || activeGameStatus?.status === ActiveGameStatus.COMPLETE_SUCCESS;

   const getScreenContainerClass = () => {
      let baseClass = "screen-container";
      if (activeGameStatus?.gametype === GameTypes.Competitive) return baseClass;

      if (activeGameStatus?.status === ActiveGameStatus.COMPLETE_FAILURE) return `${baseClass} bg-screen-red`;
      if (activeGameStatus?.status === ActiveGameStatus.COMPLETE_SUCCESS) return `${baseClass} bg-screen-green`;

      return baseClass;
   };

   return (
      <div className={getScreenContainerClass()}>
         <div className={`tv-slider ${activeGameStatus?.gametype === GameTypes.Competitive ? "red-template" : "green-template"} full-slider`}>
            {!isRunningGameCompleted && activeGameStatus?.gametype === GameTypes.Competitive && (
               <CompetitiveGame
                  gameDisplayType={gameTemplate?.gameDisplayType}
                  runningGameResult={activeGameStatus}
                  isPlaying={activeGameStatus.status === ActiveGameStatus.RUNNING}
                  key={activeGameStatus.status}
                  setRemainingTime={setRemainingTime}
               />
            )}
            {!isRunningGameCompleted && activeGameStatus?.gametype === GameTypes.Cooperative && (
               <CooperativeGame
                  gameDisplayType={gameTemplate?.gameDisplayType}
                  showHearts={gameTemplate?.showHearts}
                  runningGameResult={activeGameStatus}
                  isPlaying={activeGameStatus.status === ActiveGameStatus.RUNNING}
                  key={activeGameStatus.status}
                  setRemainingTime={setRemainingTime}
               />
            )}
            {isRunningGameCompleted && activeGameStatus?.gametype === GameTypes.Competitive && (
               <CompetitiveLevelCompleted runningGameResult={activeGameStatus} />
            )}
            {isRunningGameCompleted && activeGameStatus?.gametype === GameTypes.Cooperative && (
               <CooperativeLevelCompleted gameTemplate={gameTemplate} runningGameResult={activeGameStatus} remainingTime={remainingTime} />
            )}
         </div>
      </div>
   );
}
