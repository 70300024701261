const helpers = {
   helper1: function() {
   },
   getUrlVariable: function(variable) {
      var query = window.location.search.substring(1);
      // console.log(query)//"app=article&act=news_content&aid=160990"
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
         var pair = vars[i].split("=");
         // console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ]
         if (pair[0] == variable) {
            return pair[1];
         }
      }
      return false;
   },
   groupArr: function(data, n) {
      var group = [];
      for (var i = 0, j = 0; i < data.length; i++) {
         if (i >= n && i % n === 0) j++;
         group[j] = group[j] || [];
         group[j].push(data[i]);
      }
      return group;
   },
   parseJwt: function(token) {
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
         window
            .atob(base64)
            .split("")
            .map(function(c) {
               return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join(""),
      );

      return JSON.parse(jsonPayload);
   },
   getGameType: function(gameTypeIsCooperative) {
      return gameTypeIsCooperative ? "COOPERATIVE" : "COMPETITIVE";
   },
   getMaxLevel: function(players, levels) {
      let maxLevelPostion = 0;
      let maxLevel = 0;
      players.map(player => {
         if (player.playerLevelId && player.playerLevelId > maxLevel) {
            maxLevelPostion = player.playerLevelId;
         }
      });

      levels.map(level => {
         if (level.id === maxLevelPostion) {
            maxLevel = level.level;
         }
      });

      return maxLevel;
   },
};

export default helpers;
