import { requestJson, RequestMethod } from "helpers/httpHelper";

export const getUserNickname = async cardUid => {
   const response = await requestJson(`cards?cardUID=${cardUid}`, RequestMethod.GET, {
      useAccessToken: true,
      headers: [{ name: "Content-Type", value: "application/json" }],
   });
   return response.response.data[0].userid.nickname;
};

export const getAccountSummary = async (gameType, nickname) => {
   const response = await requestJson(`scores/summary?gameType=${gameType}&userkey=${nickname}`, RequestMethod.GET, {
      useAccessToken: true,
      headers: [{ name: "Content-Type", value: "application/json" }],
   });
   return response.response.data[0];
};

export const getAccountSummaryCards = async cardUid => {
   const response = await requestJson(`rooms/1/games/1/levels/scores?cardUID=${cardUid}`, RequestMethod.GET, {
      useAccessToken: true,
      headers: [{ name: "Content-Type", value: "application/json" }],
   });
   return response.response.data[0];
};

export const getAllRooms = async () => {
   const response = await requestJson(`rooms/1/11`, RequestMethod.GET, {
      useAccessToken: true,
   });
   return response.response.data;
};

export const getAllGamesForRoom = async (roomId, gameType) => {
   const response = await requestJson(`rooms/${roomId}/games?gameType=${gameType}`, RequestMethod.GET, {
      useAccessToken: true,
   });
   return response.response.data;
};

export const getScores = async (gameId, roomId, cardUid) => {
   const response = await requestJson(`rooms/${roomId}/games/${gameId}/levels/scores?cardUID=${cardUid}`, RequestMethod.GET, {
      useAccessToken: true,
   });
   return response.response.data[0].levelidScore;
};
