import React, { useEffect } from "react";
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from "pure-react-carousel";
import GameSelection from "./GameSelection";
import helpers from "../helpers/helpers";
import ArrowGameTop from "../assets/img/sageata_sus_verde.webp";
import ArrowGameBottom from "../assets/img/sageata_jos_verde.webp";
import ArrowGameRedTop from "../assets/img/sageata_sus_rosie.webp";
import ArrowGameRedBottom from "../assets/img/sageata_jos_rosie.webp";

const GameSelectionCarousel = ({ games, gameTypeIsCooperative, playAudio }) => {
   const sliderGames = helpers.groupArr(games, 4); //grupare pe numarul de elemente ce trebuie afisate in carousel per slide

   useEffect(() => {
      const playAudioElements = document.getElementsByClassName("button-sound");

      for (let i = 0; i < playAudioElements.length; i++) {
         playAudioElements[i].addEventListener("click", playAudio);
      }
      return () => {
         for (let i = 0; i < playAudioElements.length; i++) {
            playAudioElements[i].removeEventListener("click", playAudio);
         }
      };
   }, []);

   return (
      <CarouselProvider
         naturalSlideWidth={155}
         naturalSlideHeight={50}
         totalSlides={sliderGames.length}
         dragEnabled={false}
         disableAnimation={true}
         touchEnabled={false}
         className={"game-selection-carousel"}
         // orientation={'vertical'}
      >
         <Slider>
            {sliderGames.map((games, index) => (
               <Slide index={index} key={index}>
                  {games.map((elem, index2) => (
                     <GameSelection key={elem.id} game={elem} />
                  ))}
               </Slide>
            ))}
         </Slider>

         <ButtonBack>
            <img className="arrow-game button-sound" src={gameTypeIsCooperative ? ArrowGameTop : ArrowGameRedTop} />
         </ButtonBack>
         <ButtonNext>
            <img className="arrow-game button-sound"
                 src={gameTypeIsCooperative ? ArrowGameBottom : ArrowGameRedBottom} />
         </ButtonNext>
      </CarouselProvider>
   );
};

export default GameSelectionCarousel;
