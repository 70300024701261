import React from "react";

const Border = ({ isInWaiver }) => {
   return (
      <>
         <span className={"border-top"}></span>
         <span className={`border-bottom ${isInWaiver && "border-bottom-waiver"}`}></span>
         <span className={`border-left ${isInWaiver && "border-left-waiver"}`}></span>
         <span className={`border-right ${isInWaiver && "border-right-waiver"}`}></span>
         <span className={"blur-x blur-top"}></span>
         <span className={`blur-x blur-bottom ${isInWaiver && "blur-bottom-waiver"}`}></span>
         <span className={`blur-y blur-left ${isInWaiver && "blur-left-waiver"}`}></span>
         <span className={`blur-y blur-right ${isInWaiver && "blur-right-waiver"}`}></span>
      </>
   );
};

export default Border;
