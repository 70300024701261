import React from "react";
import PinBtn from "../assets/img/Your-pin-is.webp";
import FinBtn from "../assets/img/Finalize.webp";
import { useNavigate } from "react-router-dom";


const Pin = () => {
   const navigate = useNavigate();

   return (<div className="tablet-container reg-confirm">
      <div className="w-80 m-auto">
         <img className="w-img" src={PinBtn} />
         <h1 className="main-text font12 line-h12 stroke1">123123123</h1>
         <img className="w-img bottom--20" src={FinBtn} onClick={() => {
            navigate("/");
         }} />
      </div>
   </div>);
};

export default Pin;
