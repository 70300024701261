import React from "react";

const GamesTable = ({ gameTypeIsCooperative, selectedRoom, setSelectedRoom, roomsWithIcons, setRoomId }) => {
   const row1 = roomsWithIcons.filter((room, index) => index === 0 || index === 4 || index === 8);
   const row2 = roomsWithIcons.filter((room, index) => index === 1 || index === 5 || index === 9);
   const row3 = roomsWithIcons.filter((room, index) => index === 2 || index === 6 || index === 10);
   const row4 = roomsWithIcons.filter((room, index) => index === 3 || index === 7 || index === 11);

   const displayCorrectName = name => {
      return name.split("_")[0];
   };

   const onClickRoom = room => {
      setSelectedRoom(displayCorrectName(room.name));
      setRoomId(room.id);
   };

   return (
      <div className={`row pt3 pb3 text-white ${gameTypeIsCooperative ? "checkscore-green-bg" : "checkscore-red-bg"}`}>
         <div className="col-md-12">
            <p className="font3 font-weight-600 text-center pt-3 pb-1">{gameTypeIsCooperative ? "GAMES" : "TOTAL GAME WINS TODAY"}</p>
         </div>
         <div className="games-table">
            <table className="table text-white">
               <tbody>
                  <tr className="font-weight-300">
                     {row1.map(room => {
                        return (
                           <td onClick={() => onClickRoom(room)} key={room.id}>
                              <p
                                 className={`font2 font-style-normal font-weight-600 mb-0 text-uppercase ${
                                    selectedRoom !== displayCorrectName(room.name) && gameTypeIsCooperative === true
                                       ? "cff3b1"
                                       : selectedRoom !== displayCorrectName(room.name) && gameTypeIsCooperative === false
                                       ? "ffbfbf"
                                       : ""
                                 }`}
                              >
                                 <img
                                    className="me-1 game-rank-icon"
                                    src={require(`assets/img/checkscore/${gameTypeIsCooperative ? room.iconGreen : room.iconRed}`)}
                                    alt=""
                                 />
                                 {displayCorrectName(room.name)}
                              </p>
                           </td>
                        );
                     })}
                  </tr>
                  <tr className="font-weight-300">
                     {row2.map(room => (
                        <td onClick={() => onClickRoom(room)} key={room.id}>
                           <p
                              className={`font2 font-style-normal font-weight-600 mb-0 text-uppercase ${
                                 selectedRoom !== displayCorrectName(room.name) && gameTypeIsCooperative === true
                                    ? "cff3b1"
                                    : selectedRoom !== displayCorrectName(room.name) && gameTypeIsCooperative === false
                                    ? "ffbfbf"
                                    : ""
                              }`}
                           >
                              <img
                                 className="me-1 game-rank-icon"
                                 src={require(`assets/img/checkscore/${gameTypeIsCooperative ? room.iconGreen : room.iconRed}`)}
                                 alt=""
                              />
                              {displayCorrectName(room.name)}
                           </p>
                        </td>
                     ))}
                  </tr>
                  <tr className="font-weight-300">
                     {row3.map(room => (
                        <td onClick={() => onClickRoom(room)} key={room.id}>
                           <p
                              className={`font2 font-style-normal font-weight-600 mb-0 text-uppercase ${
                                 selectedRoom !== displayCorrectName(room.name) && gameTypeIsCooperative === true
                                    ? "cff3b1"
                                    : selectedRoom !== displayCorrectName(room.name) && gameTypeIsCooperative === false
                                    ? "ffbfbf"
                                    : ""
                              }`}
                           >
                              <img
                                 className="me-1 game-rank-icon"
                                 src={require(`assets/img/checkscore/${gameTypeIsCooperative ? room.iconGreen : room.iconRed}`)}
                                 alt=""
                              />
                              {displayCorrectName(room.name)}
                           </p>
                        </td>
                     ))}
                  </tr>
                  <tr className="font-weight-300">
                     {row4.map(room => (
                        <td onClick={() => onClickRoom(room)} key={room.id}>
                           <p
                              className={`font2 font-style-normal font-weight-600 mb-0 text-uppercase ${
                                 selectedRoom !== displayCorrectName(room.name) && gameTypeIsCooperative === true
                                    ? "cff3b1"
                                    : selectedRoom !== displayCorrectName(room.name) && gameTypeIsCooperative === false
                                    ? "ffbfbf"
                                    : ""
                              }`}
                           >
                              <img
                                 className="me-1 game-rank-icon"
                                 src={require(`assets/img/checkscore/${gameTypeIsCooperative ? room.iconGreen : room.iconRed}`)}
                                 alt=""
                              />
                              {displayCorrectName(room.name)}
                           </p>
                        </td>
                     ))}
                  </tr>
               </tbody>
            </table>
         </div>
      </div>
   );
};

export default GamesTable;
