import React from "react";
import { Outlet } from "react-router-dom";

const SelectionScreenTemplate = () => {
   return (
      <div className="selectionScreen">
         <Outlet />
      </div>
   );
};

export default SelectionScreenTemplate;
