import React from 'react'
import {Outlet} from "react-router-dom";

const TabletScreen = () => {

    return (<div className="tabletScreen">
        <Outlet/>
    </div>)

}

export default TabletScreen;
