import React, { useEffect, useState } from "react";

import authApi from "api/auth.service";
import { Outlet } from "react-router-dom";
import { DeviceSettings } from "../constants/api.constants";

export default function AdminRoutes() {
   const [isUserLoggedIn, setUserLoggedIn] = useState(false);

   const loginAdmin = async () => {
      // if (!authApi.isUserAuthenticated()) {
      let result = await authApi.login(DeviceSettings.nickname, DeviceSettings.password);
      if (result.data.status.code === 100 || !authApi.isUserAuthenticated()) {
         alert("Login error admin.");
      }
      // }

      setUserLoggedIn(authApi.isUserAuthenticated());
   };


   //TODO alin: Se fac 2 request-uri in loc de unul pe login
   /*
   Functia se executa o data la jumatate de ora si se face relogin. Nu se mai foloseste functia isUserAuthenticated pentru a verifica token-ul
   inainte de a face un request.
    */
   useEffect(() => {
      loginAdmin();
      const reLogin = setInterval(async () => {
         await authApi.login(DeviceSettings.nickname, DeviceSettings.password);
      }, DeviceSettings.reLoginInterval);

      return () => clearInterval(reLogin);
   }, []);

   // TODO bogdanla: Add loading screen
   return <>{!isUserLoggedIn ? <div>Loading...</div> : <Outlet />}</>;
}
