import axios from "axios";
import { API_URL, ActiveGameStatus, DeviceSettings } from "constants/api.constants";
import AuthService from "api/auth.service";
import { requestJson, RequestMethod } from "helpers/httpHelper";

const getGames = (roomId, gameType) => {
   setTimeout(function() {
   }, 500);

   let user = AuthService.getCurrentUser();
   const headers = {
      Authorization: "Bearer " + user.token,
   };

   return axios.get(API_URL + "rooms/" + roomId + "/games?gameType=" + gameType, { headers }).then(response => {
      return response.data;
   });
};

const getLevelsByGame = (roomId, gameId, gameType) => {
   let user = AuthService.getCurrentUser();
   const headers = {
      Authorization: "Bearer " + user.token,
   };

   return axios.get(API_URL + "rooms/" + roomId + "/games/" + gameId + "?gameType=" + gameType, { headers });
};

const getColors = () => {
   let user = AuthService.getCurrentUser();
   const headers = {
      Authorization: "Bearer " + user.token,
   };

   return axios.get(API_URL + "colors?type=USER_COLOR", { headers });
};

const getPlayerScore = (roomId, gameId, levelId, cardUid, gameType) => {
   let user = AuthService.getCurrentUser();
   const headers = {
      Authorization: "Bearer " + user.token,
   };

   return axios.get(
      API_URL + "rooms/" + roomId + "/games/" + gameId + "/levels/" + levelId + "/scores?cardUID=" + cardUid + "&gameType=" + gameType,
      { headers },
   );
};

const getCardInfo = cardUid => {
   let user = AuthService.getCurrentUser();
   const headers = {
      Authorization: "Bearer " + user.token,
   };

   return axios.get(API_URL + "cards?cardUID=" + cardUid, { headers });
};

const getActiveGame = async (roomId, status = undefined) => {
   const result = await requestJson(`runninggames/rooms/${roomId}/activegame`, RequestMethod.GET, {
      useAccessToken: true,
      queryStringParams: {
         status: status,
      },
   });

   return result;
};

const getGameStatus = async (roomId, gameId) => {
   const result = await requestJson(`runninggames/rooms/${roomId}/${gameId}/status`, RequestMethod.GET, {
      useAccessToken: true,
   });

   return result;
};

// TODO alin: de discutat de ce punem `` in loc de ghilimele?
const startGame = async (roomId, gameId, levelId, userIdList) => {
   const result = await requestJson(`runninggames/rooms/${roomId}/add`, RequestMethod.POST, {
      useAccessToken: true,
      queryStringParams: {
         gameId: gameId,
         levelId: levelId,
         userIdList: userIdList,
      },
   });

   return result;
};

const getTopHighestScores = async () => {
   const result = await requestJson(`scores/tophighscoretodays`, RequestMethod.GET, {
      useAccessToken: true,
   });

   return result;
};

const scanBraceletTroughAPI = async () => {
   const result = await requestJson(`nfcdata/read`, RequestMethod.GET, {
      useAccessToken: true,
      queryStringParams: {
         deviceId: DeviceSettings.deviceID,
      },
   });
   
   if (result.response?.status.description === "Not found.") return "Not found.";
   return result.response?.data[0];
};

const deleteBraceletTroughAPI = async deviceID => {
   const result = await requestJson(`nfcdata/delete/${deviceID}`, RequestMethod.DELETE, {
      useAccessToken: true,
   });

   return result.response?.status?.code;
};

const selectionScreenApi = {
   getGames,
   getLevelsByGame,
   getColors,
   getCardInfo,
   getPlayerScore,
   getActiveGame,
   getGameStatus,
   startGame,
   getTopHighestScores,
   scanBraceletTroughAPI,
   deleteBraceletTroughAPI,
};

export default selectionScreenApi;
