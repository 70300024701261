import React from "react";
import FinBtn from "assets/img/Finalize.webp";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import ImgBack from "assets/img/Tablet_screen_Back_Btn.webp";
import { toast } from "react-toastify";

function Settings() {
   const { register, handleSubmit } = useForm({
      defaultValues: {
         nickname: JSON.parse(localStorage.getItem("deviceSettings"))?.nickname,
         password: JSON.parse(localStorage.getItem("deviceSettings"))?.password,
         deviceID: JSON.parse(localStorage.getItem("deviceSettings"))?.deviceID,
         scanInterval: JSON.parse(localStorage.getItem("deviceSettings"))?.scanInterval,
      },
      mode: "onSubmit",
   });
   const onSubmit = data => {
      localStorage.setItem("deviceSettings", JSON.stringify(data));
      toast.success("Settings saved!");
   };

   return (
      <div className="main-container settings-container">
         <div className="pt-3">
            <Link to={"/"}>
               <img className={"button-back"} src={ImgBack} />
            </Link>
            <h1>Settings</h1>
         </div>
         <div className="col-lg-12">
            <form onSubmit={handleSubmit(onSubmit)}>
               <div className="col-lg-12 mx-auto pb-3">
                  <label className="label-settings" htmlFor="">
                     Nickname
                  </label>
                  <input type="text" className="form-settings" placeholder="nickname" {...register("nickname")} />
               </div>
               <div className="col-lg-12 mx-auto pt-3 pb-3">
                  <label className="label-settings" htmlFor="">
                     Password
                  </label>
                  <input type="text" className="form-settings" placeholder="password" {...register("password")} />
               </div>
               <div className="col-lg-12 mx-auto pt-3 pb-3">
                  <label className="label-settings" htmlFor="">
                     Device ID
                  </label>
                  <input type="text" className="form-settings" placeholder="deviceID" {...register("deviceID")} />
               </div>
               <div className="col-lg-12 mx-auto pt-3 pb-3">
                  <label className="label-settings" htmlFor="">
                     Interval to scan bracelets(ms)
                  </label>
                  <input type="text" className="form-settings"
                         placeholder="Scan interval timer" {...register("scanInterval")} />
               </div>
               <div>
                  <input type="image" className="w-img bg-transparent" src={FinBtn} onClick={handleSubmit(onSubmit)} />
               </div>
            </form>
         </div>
      </div>
   );
}

export default Settings;
