import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/main.scss";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Register from "pages/Register";
import TabletScreen from "layouts/TabletScreen";
import TvScreenLayout from "layouts/TvScreenLayout";
import Homepage from "pages/Homepage";
import SelectionScreenLayout from "layouts/SelectionScreen";
import Login from "pages/Login";
import ScanBracelet from "pages/ScanBracelet";
import RegistrationConfirmed from "pages/RegistrationConfirmed";
import GameOn from "pages/GameOn";
import LostBracelet from "pages/LostBracelet";
import Error from "pages/Error";
import Pin from "pages/Pin";
import Selection from "pages/Selection/Selection";
import Ball from "pages/TvScreen/Ball/Ball";
import TopScore from "pages/TvScreen/TopScore/TopScore";
import TestPromise from "pages/TestPromise";
import TvScreenOld from "pages/TvScreen/TvScreenOld";
import TvScreenGameStart from "pages/TvScreen/GameStart/TvScreenGameStart";
import AdminRoutes from "containers/AdminRoutes";
import { ToastContainer } from "react-toastify";
import Settings from "pages/Settings";
import CheckScore from "pages/CheckScore";
import TvScreenTest from "pages/TvScreen/__test__/TvScreenTest";
import TvScreen from "pages/TvScreen/TvScreen";
import TvScreenProfiler from "pages/TvScreen/TvScreenProfiler";

function App() {
   // TODO bogdanla: implement something to logout when switching from AdminRoutes to TabletRoutes
   return (
      <React.Fragment>
         <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
         />
         <Router>
            <Routes>
               <Route element={<TabletScreen />}>
                  <Route exact path="/" element={<Homepage />} />
                  <Route path="/register" element={<Register />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/scan" element={<ScanBracelet />} />
                  <Route path="/lost-bracelet" element={<LostBracelet />} />
                  <Route path="/registration-confirmed" element={<RegistrationConfirmed />} />
                  <Route path="/game-on" element={<GameOn />} />
                  <Route path="/error" element={<Error />} />
                  <Route path="/pin" element={<Pin />} />
                  <Route path="/test" element={<TestPromise />} />
                  <Route path="/settings" element={<Settings />} />
               </Route>

               <Route element={<AdminRoutes />}>
                  <Route element={<TvScreenLayout />}>
                     <Route path="tv-screen/:roomId" element={<TvScreen />} />
                     <Route path="tv-screen-old/:roomId" element={<TvScreenOld />} />
                     <Route path="tv-screen-profiler/:roomId" element={<TvScreenProfiler />} />
                     <Route exact path="/tv-screen/:roomId/start" element={<TvScreenGameStart />} />
                     <Route exact path="/tv-screen/:roomId/ball" element={<Ball />} />
                     <Route exact path="/tv-screen/:roomId/score" element={<TopScore />} />
                     <Route exact path="/tv-screen-test-room" element={<TvScreenTest />} />
                     <Route path="/checkscore" element={<CheckScore />} />
                  </Route>

                  <Route element={<SelectionScreenLayout />}>
                     <Route path="/selection" element={<Selection />} />
                  </Route>
               </Route>
            </Routes>
         </Router>
      </React.Fragment>
   );
}

export default App;
