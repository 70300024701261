import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import LogTxt from "../assets/img/Login.webp";
import LogBtn from "../assets/img/Log_in.webp";
import RegBtn from "../assets/img/Register_Now.webp";
import ImgBack from "../assets/img/Tablet_screen_Back_Btn.webp";
import Border from "../components/Border";
import AuthService from "../api/auth.service";
import Waiver from "components/modals/Waiver";

const inputTypes = {
   nickname: "nickname",
   password: "password",
   waiverName: "waiverName",
   waiverSurname: "waiverSurname",
};
const minCharacters = 2;

const Login = props => {
   const navigate = useNavigate();
   const [disableLogin, setDisableLogin] = useState(false);
   const [nickname, setNickname] = useState("");
   const [password, setPassword] = useState("");
   const [errors, setErrors] = useState({ nickname: undefined, password: undefined });
   const [showWaiver, setShowWaiver] = useState(false);
   const [userId, setUserId] = useState("");
   const [waiverData, setWaiverData] = useState({});
   const [form, setForm] = useState({
      age: { value: null, error: undefined },
      waiverName: { value: "", error: undefined },
      waiverSurname: { value: "", error: undefined },
   });

   const validateNickname = () => {
      if (nickname === undefined || nickname === "") {
         return "Please fill in this field correctly!";
      }
      return undefined;
   };

   const validatePassword = () => {
      if (password === undefined || password === "") {
         return "Please fill in this field correctly!";
      } else if (password.length < minCharacters) {
         return `Minimum length is ${minCharacters}`;
      }
      return undefined;
   };

   const validateWaiverName = () => {
      if (form.waiverName.value === undefined || form.waiverName.value === "") {
         return "Please fill in this field correctly!";
      }
      return undefined;
   };
   const validateWaiverSurname = () => {
      if (form.waiverSurname.value === undefined || form.waiverSurname.value === "") {
         return "Please fill in this field correctly!";
      }
      return undefined;
   };

   const handleInputChage = (value, type) => {
      if (type === inputTypes.nickname) {
         setNickname(value);
         if (errors.nickname !== undefined) setErrors({ nickname: undefined, password: errors.password });
      }
      if (type === inputTypes.password) {
         setPassword(value);
         if (errors.password !== undefined) setErrors({ nickname: errors.nickname, password: undefined });
      }
      if (type === inputTypes.waiverName) {
         setForm(prevState => {
            return {
               ...prevState,
               waiverName: {
                  ...prevState.waiverName,
                  value: value,
               },
            };
         });
      }
      if (type === inputTypes.waiverSurname) {
         setForm(prevState => {
            return {
               ...prevState,
               waiverSurname: {
                  ...prevState.waiverSurname,
                  value: value,
               },
            };
         });
      }
   };

   const validate = types => {
      const newErrors = errors;

      if (types.includes(inputTypes.nickname)) {
         newErrors.nickname = validateNickname();
      }
      if (types.includes(inputTypes.password)) {
         newErrors.password = validatePassword();
      }

      setErrors({ nickname: newErrors.nickname, password: newErrors.password });
      return newErrors.nickname !== undefined || newErrors.password !== undefined;
   };

   const validateWaiver = types => {
      const newErrors = {
         waiverName: undefined,
         waiverSurname: undefined,
      };

      if (types.includes(inputTypes.waiverName)) {
         newErrors.waiverName = validateWaiverName();
      }
      if (types.includes(inputTypes.waiverSurname)) {
         newErrors.waiverSurname = validateWaiverSurname();
      }

      setForm(prevState => {
         return {
            ...prevState,
            waiverName: {
               ...prevState.waiverName,
               error: newErrors.waiverName,
            },
            waiverSurname: {
               ...prevState.waiverSurname,
               error: newErrors.waiverSurname,
            },
         };
      });

      return newErrors.waiverName !== undefined || newErrors.waiverSurname !== undefined;
   };

   const onSubmit = () => {
      if (disableLogin) return;
      setDisableLogin(true);
      const hasError = validate([inputTypes.nickname, inputTypes.password]);
      if (hasError === true) {
         setDisableLogin(false);
         return;
      }

      AuthService.login(nickname, password).then(
         response => {
            if (response.data.status.code !== 0) {
               navigate("/error");
               return;
            }

            if (response.data.data[0].waiverid !== null) {
               navigate("/scan");
               return;
            }
            setUserId(response.data.data[0].userid);
            AuthService.getWaiverText().then(response => {
               if (response.isSuccess) {
                  setShowWaiver(true);
                  setWaiverData(response.response.data[0]);
               } else navigate("/error");
            });
         },
         error => {
            const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            navigate("/error");
         },
      );
   };

   const onSubmitWaiver = async () => {
      if (form.age.value === null) {
         setForm(prevState => {
            return {
               ...prevState,
               age: {
                  ...prevState.age,
                  error: "Please fill in this field correctly!",
               },
            };
         });
         return;
      }

      const hasError = validateWaiver([inputTypes.waiverName, inputTypes.waiverSurname]);
      if (hasError === true) return;
      console.log(userId, form.age.value, form.waiverName.value, form.waiverSurname.value, waiverData.id);
      const response = await AuthService.editSignWaiver(userId, form.age.value, form.waiverName.value, form.waiverSurname.value, waiverData.id);
      console.log(response);
      if (response.isSuccess) navigate("/scan");
      else navigate("/error");
   };

   return (
      <div className="main-container">
         <Link to={"/"}>
            <img className={"button-back"} src={ImgBack} />
         </Link>
         <div className="text-center">
            <img className="log-img" src={LogTxt} />
         </div>
         <div className="form-group">
            <Border />
            <input
               placeholder="nickname"
               onChange={e => handleInputChage(e.target.value, inputTypes.nickname)}
               className={`${errors.nickname !== undefined ? "is-invalid" : "is-valid"}`}
            />
            <br />
            <p className={"error-message"}> {errors.nickname}</p>
            <br />
         </div>
         <div className="form-group mt-3w">
            <Border />
            <input
               autoComplete="new-password"
               className={`${errors.password !== undefined ? "is-invalid" : "is-valid"}`}
               placeholder="password"
               type="password"
               onChange={e => handleInputChage(e.target.value, inputTypes.password)}
            />
            <br />
            <p className={"error-message"}> {errors.password}</p>
            <br />
         </div>

         <div className="form-group mt4">
            <input type="image" className="w-img2 bg-transparent" src={LogBtn} onClick={onSubmit} />
         </div>
         <div className={"mt-7w"}>
            <p className={"p-text mb-3w"}>Don't have an account?</p>
            <Link to={"/register"}>
               <img className="w-img" src={RegBtn} />
            </Link>
            <Link to={"/lost-bracelet"} className={"link mt-3w"}>
               Lost bracelet
            </Link>
         </div>

         <Waiver
            isWaiverForLogin={true}
            show={showWaiver}
            handleInputChage={handleInputChage}
            inputTypes={inputTypes}
            form={form}
            setForm={setForm}
            waiverText={waiverData.waiver}
            handleAccept={() => onSubmitWaiver()}
         />
      </div>
   );
};

export default Login;
