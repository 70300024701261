import React from "react";
import { ProgressBar } from "react-bootstrap";

export default function CustomProgressBarWithHex({ value, text, hex }) {
   return (
      <ProgressBar
         className={`div-progress font-15 progress-sc mt4`}
         now={value}
         backgroundColor={hex}
         style={{
            backgroundColor: hex,
            "--bs-progress-bar-bg": hex,
            boxShadow: `inset 0 0 6px 2px ${hex}, 0 0 12px 3px ${hex}, 0 0 12px 3px ${hex}`,
         }}
         label={<div className={"progress-label"}>{text}</div>}
      />
   );
}
