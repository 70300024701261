import React from "react";
import TryAgain from "../assets/img/Please_try_again.webp";
import ErrorImg from "../assets/img/ERROR.webp";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const Error = () => {
   const navigate = useNavigate();

   useEffect(() => {
      const interval = setInterval(() => {
         navigate("/");
      }, 10000);

      return () => clearInterval(interval);
   }, []);

   return (
      <div
         className="tablet-container"
         onClick={() => {
            navigate("/");
         }}
      >
         <div className="w-80">
            <img className={"w-100"} src={ErrorImg} alt={"Error"} />
            <img className={"w-100"} src={TryAgain} alt={"Please try again"} />
         </div>
      </div>
   );
};

export default Error;
