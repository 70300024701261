import React, { useState, useEffect } from "react";
import Bracelet from "assets/img/Scan_your_bracelet_to_check_your_score.webp";
import CheckScoreResults from "components/CheckScoreResults";
import { getUserNickname } from "api/checkscore";
import { DeviceSettings } from "constants/api.constants";
import selectionScreenApi from "api/selection.screen";
import AuthService from "api/auth.service";

const CheckScore = () => {
   const [currentCardUid, setCurrentCardUid] = useState("");
   const [isScanned, setIsScanned] = useState(false);
   const [userCheckScore, setUserCheckScore] = useState({
      nickname: "",
      cardUid: "",
   });

   console.log();
   // send request-> scan bracelet -> get cardUid -> at every deviceSettings.scanInterval (set it from settings page) (default 1sec)
   useEffect(() => {
      if (!isScanned) {
         
         const scanBraceletInterval = setInterval(async () => {
            const responseFromScan = await selectionScreenApi.scanBraceletTroughAPI();
            const idBracelet = responseFromScan?.id;
            if (responseFromScan !== "Not found.") {
               setCurrentCardUid(responseFromScan?.data);
               setUserCheckScore({
                  ...userCheckScore,
                  cardUid: responseFromScan?.data,
                  nickname: await getUserNickname(responseFromScan?.data),
               });
            }
            if (idBracelet && currentCardUid && currentCardUid !== undefined && currentCardUid !== "Not found.") {
               const isDeletedFromScan = await selectionScreenApi.deleteBraceletTroughAPI(idBracelet);
               if (isDeletedFromScan === 0) setCurrentCardUid("");
               setIsScanned(true);
            }
         }, DeviceSettings.scanInterval);
         return () => clearInterval(scanBraceletInterval);

      } else {
         const rescanBraceletSequence = setInterval(async () => {
            setIsScanned(false);
         }, DeviceSettings.checkScoreScanInterval);
         return () => clearInterval(rescanBraceletSequence);
      }
   }, [currentCardUid, isScanned]);

   return (
      <div className="tabletScreen">
         {!isScanned ? (
            <div className="tablet-container">
               <img className={"w-100"} src={Bracelet} alt={"Scan Your Bracelet"} />
            </div>
         ) : (
            <CheckScoreResults userCheckScore={userCheckScore} setIsScanned={setIsScanned} />
         )}
      </div>
   );
};

export default CheckScore;
