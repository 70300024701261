import React from "react";
import SimpleCountDown from "components/Sliders/SimpleCountDown";
import { CompetitiveGameDisplayType } from "constants/games.constants";
import CustomProgressBarWithHex from "components/CustomProgressBarWjthHex";

function CompetitiveGamePrivate({ gameDisplayType, showName, runningGameResult, isPlaying, setRemainingTime }) {
   if (gameDisplayType === undefined || runningGameResult === undefined) {
      return <React.Fragment />;
   }

   return (
      <div className="container">
         <div className="row pl8 pr8">
            {gameDisplayType === CompetitiveGameDisplayType.NONE ? (
               <div style={{ margin: "5% auto 5% auto", width: "50%" }}>
                  <div className="col-md-12">
                     <SimpleCountDown
                        startFrom={runningGameResult.playtime}
                        duration={runningGameResult.playtime}
                        isPlaying={isPlaying}
                        setRemainingTime={setRemainingTime}
                        template="red"
                     />
                  </div>
               </div>
            ) : (
               <div className="col-md-6 progress-bar-box">
                  <SimpleCountDown
                     startFrom={runningGameResult.playtime}
                     duration={runningGameResult.playtime}
                     isPlaying={isPlaying}
                     setRemainingTime={setRemainingTime}
                     template="red"
                  />
               </div>
            )}
            {gameDisplayType === CompetitiveGameDisplayType.NICKNAME_SCORE && (
               <div className="col-md-6 div-score">{<ScoreComponentWithoutProgressBar runningGameResult={runningGameResult} showName={true} />}</div>
            )}
            {gameDisplayType === CompetitiveGameDisplayType.TEAM_SCORE && (
               <div className="col-md-6 div-score">{<ScoreComponent runningGameResult={runningGameResult} showName={true} />}</div>
            )}
            {gameDisplayType === CompetitiveGameDisplayType.SCORE && (
               <div className="col-md-6 div-score">{<ScoreComponentWithoutProgressBar runningGameResult={runningGameResult} showName={false} />}</div>
            )}
            {gameDisplayType === CompetitiveGameDisplayType.ELAPSE && (
               <div className="col-md-6 div-score">
                  {<ElapsedComponent runningGameResult={runningGameResult} showName={true} isPlaying={isPlaying} />}
               </div>
            )}
         </div>
      </div>
   );
}

const ScoreComponent = ({ runningGameResult, showName }) => {
   return (
      <>
         {runningGameResult.usersList.map((x, key) => {
            const progress = x.competitivescore === null ? 0 : x.competitivescore / runningGameResult.goal;
            return (
               <div className="w-100" key={key}>
                  {showName && <p className={"text-team"}>{x.nickname}</p>}
                  <CustomProgressBarWithHex value={progress * 100} text={`${x.competitivescore ?? 0}/${runningGameResult.goal}`} hex={x.rgbval} />
               </div>
            );
         })}
      </>
   );
};

const ScoreComponentWithoutProgressBar = ({ runningGameResult, showName }) => {
   return (
      <>
         {runningGameResult.usersList.map((x, key) => {
            return (
               <div className="w-100" key={key}>
                  {showName && <p className={"text-team"}>{x.nickname}</p>}
                  <CustomProgressBarWithHex value={100} text={x.competitivescore ?? 0} hex={x.rgbval} />
               </div>
            );
         })}
      </>
   );
};

function ElapsedComponent({ runningGameResult, showName, isPlaying }) {
   const [counter, setCounter] = React.useState(runningGameResult.playtime ?? 0);
   React.useEffect(() => {
      const maxCompetitiveScore = Math.max(...runningGameResult.usersList.map(x => x.competitivescore)) ?? 0;

      const timer =
         isPlaying &&
         counter + Math.round(maxCompetitiveScore / 1000) > 0 &&
         setTimeout(() => {
            const newCounter = counter - 1;
            setCounter(newCounter);
         }, 1000);
      return () => clearTimeout(timer);
   }, [counter, isPlaying]);

   return (
      <div className="div-question">
         {runningGameResult.usersList.map((x, key) => {
            return (
               <div className="div-mini-slider" key={key}>
                  {showName && <p>{x.nickname}</p>}
                  <div style={{ width: "15%" }}>
                     <SimpleCountDown
                        displayCounter={counter + Math.round(x.competitivescore / 1000)}
                        duration={runningGameResult.playtime}
                        isPlaying={false}
                        size={"small"}
                        template={x.colorname}
                     />
                  </div>
               </div>
            );
         })}
      </div>
   );
}

const CompetitiveGame = React.memo(CompetitiveGamePrivate);
export default CompetitiveGame;
