import React from "react";

const TableSelectionScreen = ({ players }) => {
   return (
      <table className={"m1"}>
         <thead>
            <tr>
               <td width={"35%"}>
                  Player <br /> Name
               </td>
               <td width={"20%"}>
                  Time <br /> Left
               </td>
               <td width={"25%"}>
                  High <br /> Score
               </td>
               <td width={"20%"}>
                  Token <br /> Reward
               </td>
            </tr>
            <tr height={"15px"}></tr>
         </thead>
         <tbody>
            {players.map((elem, index) => (
               <tr key={index} className={"tr-table"}>
                  <td>{elem?.userData?.userid?.nickname}</td>
                  <td>{elem?.timeLeft?.secondsRemains}</td>
                  <td>{elem?.highUserScore}</td>
                  <td>{elem?.tokenReward}</td>
               </tr>
            ))}
         </tbody>
      </table>
   );
};

export default TableSelectionScreen;
