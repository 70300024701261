import axios from "axios";
import { sha512 } from "js-sha512";
import { API_URL } from "constants/api.constants";
import helpers from "helpers/helpers";
import { requestJson, RequestMethod } from "helpers/httpHelper";

const LocalStorageUserKey = "user";

const register = (nickname, email, password, waiverName, waiverSurname, age, waiverId) => {
   var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
   var currentDate = new Date(Date.now() - tzoffset).toISOString().slice(0, -1) + "Z";
   // const currentDate = new Date().toJSON()
   password = sha512(password);

   return axios.post(API_URL + "users/create", {
      email,
      lastaction: currentDate,
      nickname,
      password,
      registertime: currentDate,
      signedwaiver: {
         signedtime: currentDate,
         guardianName: waiverName,
         guardianSurname: waiverSurname,
         isAdult: age === true ? 1 : 0,
         waiver: {
            id: waiverId,
         },
      },
   });
};

const login = (nickname, password) => {
   localStorage.removeItem(LocalStorageUserKey);
   password = sha512(password);

   return axios
      .post(
         API_URL + "login",
         new URLSearchParams({
            nickname,
            password,
         }),
      )
      .then(response => {
         if (response.data.data) {
            localStorage.setItem(LocalStorageUserKey, JSON.stringify(response.data.data[0]));
         }
         return response;
      });
};

const logout = () => {
   localStorage.removeItem(LocalStorageUserKey);
   return axios.post(API_URL + "signout").then(response => {
      return response.data;
   });
};

const getCurrentUser = () => {
   if (localStorage.getItem(LocalStorageUserKey) !== "undefined") return JSON.parse(localStorage.getItem(LocalStorageUserKey));

   return "";
};

const resetCard = user => {
   const headers = {
      Authorization: "Bearer " + user.token,
   };

   return axios.put(API_URL + "users/" + user.userid + "/resetcard", {}, { headers }).then(response => {
      return response;
   });
};

const setCardToUser = cardId => {
   let user = AuthService.getCurrentUser();
   const headers = {
      Authorization: "Bearer " + user.token,
   };

   return axios.put(API_URL + "cards/" + cardId + "?newUserId=" + user.userid, {}, { headers });
};

const getCardInfo = cardUid => {
   let user = AuthService.getCurrentUser();
   const headers = {
      Authorization: "Bearer " + user.token,
   };

   return axios.get(API_URL + "cards?cardUID=" + cardUid, { headers });
};

const getCardInfoAndAssignToUser = (cardUid = 4028250148) => {
   let user = AuthService.getCurrentUser();

   const headers = {
      Authorization: "Bearer " + user.token,
   };

   return axios.get(API_URL + "cards?cardUID=" + cardUid, { headers }).then(response => {
      const card = response.data.data[0];
      const res = setCardToUser(card.id).then(response => {
         return response.data.data[0];
      });
   });
};

const isUserAuthenticated = () => {
   const user = getCurrentUser();
   if (!user && user?.token === undefined) {
      return false;
   }

   try {
      const jwtObj = helpers.parseJwt(user.token);
      const tokenExpirationDate = new Date(jwtObj.exp * 1000);
      return tokenExpirationDate > new Date();
   } catch (error) {
      return false;
   }
};

const getWaiverText = async () => {
   const response = await requestJson("waivers/get", RequestMethod.GET, {
      headers: [{ name: "Content-Type", value: "application/json" }],
   });

   return response;
};

const editSignWaiver = async (userId, age, waiverName, waiverSurname, waiverid) => {
   const user = getCurrentUser();
   const isAdult = age === true ? 1 : 0;

   const response = await requestJson(
      `users/${userId}/edit?isAdult=${isAdult}&signerName=${waiverName}&signerSurname=${waiverSurname}&waiverid=${waiverid}`,
      RequestMethod.PUT,
      {
         headers: [
            { name: "Content-Type", value: "application/json" },
            { name: "Authorization", value: `Bearer ${user.token}` },
         ],
      },
   );

   return response;
};

const AuthService = {
   register,
   login,
   logout,
   getCurrentUser,
   resetCard,
   getCardInfo,
   setCardToUser,
   getCardInfoAndAssignToUser,
   isUserAuthenticated,
   getWaiverText,
   editSignWaiver,
};

export default AuthService;
