import React, { useContext, useEffect } from "react";
import { GameSelectedContext } from "../pages/Selection/Selection";

const GameSelection = ({ game }) => {
   const { gameSelected, setGameSelected, playAudio } = useContext(GameSelectedContext);
   useEffect(() => {
      const playAudioElements = document.getElementsByClassName("button-sound");

      for (let i = 0; i < playAudioElements.length; i++) {
         playAudioElements[i].addEventListener("click", playAudio);
      }
      return () => {
         for (let i = 0; i < playAudioElements.length; i++) {
            playAudioElements[i].removeEventListener("click", playAudio);
         }
      };
   }, []);

   return (
      <div
         onClick={() => setGameSelected(game)}
         className={`${gameSelected.id === game.id && "active-selection"} ${
            game.displayname.length < 11 && "font-226"
         } button-sound bg-light1 second-border col-md-6 font-19 p4 button-game-selection`}
      >
         {game.displayname}
      </div>
   );
};

export default GameSelection;
