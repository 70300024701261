import React, { useState, useRef } from "react";
import imgTopScore from "assets/img/Top_High_Scores.webp";

const TopScore = ({ highestScores }) => {
   const scores = [...Array(10)].map((x, key) => {
      if (highestScores.length > key) {
         return {
            nickname: highestScores[key].nickname,
            score: highestScores[key].score,
         };
      } else {
         return {
            nickname: "-",
            score: "-",
         };
      }
   });
   return (
      <div className="screen-container ">
         <div className="tv-slider green-template full-slider">
            <div className="container">
               <div className="row mt--20 mb--20">
                  <div className="col-md-12">
                     <img className={"w-100"} src={imgTopScore} />
                  </div>
               </div>
               <div className="row row-top-score">
                  <div className="col-md-1">
                     <p className={"font-weight-200 mb-4"}>Rank</p>
                     <p>1</p>
                     <p>2</p>
                     <p>3</p>
                     <p>4</p>
                     <p>5</p>
                     <p>6</p>
                     <p>7</p>
                     <p>8</p>
                     <p>9</p>
                     <p>10</p>
                  </div>
                  <div className="col-md-8">
                     <p className={"font-weight-200 mb-4"}>Nickname</p>
                     {scores.map((x, key) => (
                        <p key={key}>{x.nickname}</p>
                     ))}
                  </div>
                  <div className="col-md-3">
                     <p className={"font-weight-200 mb-4"}>Score</p>
                     {scores.map((x, key) => (
                        <p key={key}>{x.score}</p>
                     ))}
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default TopScore;
