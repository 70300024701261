import React, { useState, useRef, useEffect, useCallback, createContext } from "react";
import ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom";
import selectionScreenApi from "../../api/selection.screen";
import * as localApi from "../../api/local.services";
import SliderLevel from "../../components/Sliders/Slider";
import "pure-react-carousel/dist/react-carousel.es.css";
import CooperativeTxtRed from "../../assets/img/competitive_green.webp";
import CooperativeTxtGreen from "../../assets/img/cooperative_red.webp";
import CooperativeRedLeft from "../../assets/img/Energize - export assets_competitive_sageti_stanga.webp";
import CooperativeRedRight from "../../assets/img/Energize - export assets_competitive_sageti_stanga_dreapta.webp";
import CooperativeGreenLeft from "../../assets/img/Energize - export assets_cooperative_sageti_stanga.webp";
import CooperativeGreenRight from "../../assets/img/Energize - export assets_cooperative_sageti_dreapta.webp";
import GoCooperative from "../../assets/img/GO_GREEN.webp";
import GoCompetitive from "../../assets/img/GO_Red.webp";
import StartBtn from "../../assets/img/Energize - export assets_start buton gri .webp";
import StartBtn2 from "../../assets/img/start.webp";
import StartBtnRed from "../../assets/img/start_mov.webp";
import MessageCooperative from "../../assets/img/Selection_screen_Message_Coop.webp";
import MessageCompetitive from "../../assets/img/Selection_screen_Message_Comp.webp";
import GameSelectionCarousel from "../../components/GameSelectionCarousel";
import helpers from "../../helpers/helpers";
import TableSelectionScreen from "../../components/TableSelectionScreen";
import TableSelectionScreenCompetitive from "../../components/TableSelectionScreenCompetitive";
import SelectionScreenMessage from "../../components/SelectionScreenMessage";
import { ActiveGameStatus, ApiRetryTimeout, NoResultStatusCode, OkResultStatusCode, DeviceSettings } from "constants/api.constants";
import { SelectionScreenStatus, CountDownDurationInMiliseconds } from "./constants";
import { firstUserList, secondUserList } from "./__test__/testData";
import { toast } from "react-toastify";
import SoundEffect from "assets/audio/beep-click.wav";
import SimpleCountDown from "components/Sliders/SimpleCountDown";
// const imageContext = require.context('../../assets/img/howToPlay', true, /\.(webp)$/);
// const images = imageContext.keys().map(imageContext);
// console.log(images)
export const GameSelectedContext = createContext();
var userListProd =
   helpers.getUrlVariable("playerGroupId") === "first"
      ? firstUserList
      : helpers.getUrlVariable("playerGroupId") === "second"
      ? secondUserList
      : helpers.getUrlVariable("playerGroupId") === "all"
      ? [...firstUserList.filter(x => x.userData !== undefined), ...secondUserList.filter(x => x.userData !== undefined)]
      : [{}, {}, {}, {}, {}];

const Selection = props => {
   const [colors, setColors] = useState([]);
   const [gamesList, setGamesList] = useState([]);
   const [gameTypeIsCooperative, setGameTypeIsCooperative] = useState(true);
   const [gameSelected, setGameSelected] = useState("");
   const [screenStatus, setScreenStatus] = useState(SelectionScreenStatus.GameSelection);
   const [players, setPlayers] = useState(userListProd);
   const [levels, setLevels] = useState([]);
   const [levelIsDisabled, setLevelIsDisabled] = useState(false);
   const [selectedLevel, setSelectedLevel] = useState(0); //level Slider
   const [highScore, setHighScore] = useState({ highScoreToday: "-", highScore90Days: "-", highScoreAllTime: "-" });
   const [currentCardUid, setCurrentCardUid] = useState("");
   const [maxLevelSlider, setMaxLevelSlider] = useState(0);
   const [imageHowToPlayShow, setImageHowToPlayShow] = useState(false);
   const [imageHowToPlayImage, setImageHowToPlayImage] = useState(false);
   const [imageHowToPlayClose, setImageHowToPlayClose] = useState(false);
   const roomId = helpers.getUrlVariable("roomId");

   const playAudio = () => {
      new Audio(SoundEffect).play();
   };

   const handleIndexChange = index => {
      setSelectedLevel(index);
      players.map((value, index) => {
         if (value.userData?.uid) {
            getCardInfoAndScore(value.userData.uid, gameSelected, levels[selectedLevel].id);
         }
      });
   };

   const handleClickCooperative = event => {
      setGameTypeIsCooperative(value => !value);
   };

   const startGame = async (selectedRoomId, selectedGameId, selectedLevelId) => {
      const userList = players
         .filter(x => x?.userData !== undefined)
         .map((player, index) => {
            return {
               userId: player.userData.userid.id,
               colorId: gameTypeIsCooperative ? 0 : colors[index].id,
            };
         });

      var result = await selectionScreenApi.startGame(
         selectedRoomId,
         selectedGameId,
         selectedLevelId,
         userList.map(x => JSON.stringify(x)),
      );

      if (result.isSuccess && result?.response?.status.code !== OkResultStatusCode) {
         toast.error(result.response.status.description);
         return undefined;
      }

      return result?.response;
   };

   const clearState = () => {
      setScreenStatus(SelectionScreenStatus.GameSelection);
      handleResetTable();
   };

   const showCountDown = () => {
      setScreenStatus(SelectionScreenStatus.Countdown);
      localApi.openDoor();

      setTimeout(clearState, CountDownDurationInMiliseconds + 2);
   };

   const setWaitingForGameToFinish = async runningGameId => {
      setScreenStatus(SelectionScreenStatus.WaitingGameToStart);

      const gameStatusResponse = await selectionScreenApi.getGameStatus(roomId, runningGameId);
      if (!gameStatusResponse.isSuccess) {
         return;
      }

      if (gameStatusResponse.response.status.code === NoResultStatusCode) {
         showCountDown();
         return;
      } else if (gameStatusResponse.response?.data[0].status === ActiveGameStatus.WAITING_START) {
         showCountDown();
         return;
      }

      setTimeout(() => {
         setWaitingForGameToFinish(runningGameId);
      }, ApiRetryTimeout);
   };

   const handleStartGame = async () => {
      // parametrii de testare
      // const selectedRoomId = 7 ?? roomId;
      // const selectedGameId = 38 ?? gameSelected.id;
      // const selectedLevelId = 371 ?? levels[selectedLevel].id;

      const selectedRoomId = roomId;
      const selectedGameId = gameSelected.id;
      const selectedLevelId = levels[selectedLevel].id;
      let startedGameResponse = null;

      const runningGameResponse = await selectionScreenApi.getActiveGame(roomId);
      if (!runningGameResponse.isSuccess) {
         return;
      }

      if (runningGameResponse.response.status.code === NoResultStatusCode) {
         startedGameResponse = await startGame(selectedRoomId, selectedGameId, selectedLevelId);
         if (startedGameResponse === undefined) return;
         showCountDown();
         return;
      }

      const waitingGameResponse = await selectionScreenApi.getActiveGame(roomId, ActiveGameStatus.WAITING_ROOM);
      if (!waitingGameResponse.isSuccess) {
         return;
      }

      if (waitingGameResponse.response.status.code === NoResultStatusCode) {
         startedGameResponse = await startGame(selectedRoomId, selectedGameId, selectedLevelId);
         if (startedGameResponse === undefined) return;
      }

      setWaitingForGameToFinish(startedGameResponse?.data[0]?.id ?? waitingGameResponse.response.data[0].runninggameid);
   };

   const handleResetTable = () => {
      setPlayers([{}, {}, {}, {}, {}]);
      setSelectedLevel(0);
      setLevelIsDisabled(true);
   };

   const getGameType = () => {
      if (gameTypeIsCooperative) return "COOPERATIVE";

      return "COMPETITIVE";
   };

   //tabelul trebuie sa aiba mereu un numar fix de coloane, chiar daca sunt empty
   //in tabel se adauga un player doar daca acesta nu exista deja
   const handlePlayersList = playerToAdd => {
      let newPlayer = true;
      let newList = [...players];
      newList.map((value, index) => {
         if (value?.userData?.uid === playerToAdd.userData.uid) {
            // se verifica daca exista player
            newPlayer = false;
            return;
         }
      });

      if (newPlayer) {
         newList.unshift(playerToAdd);
         newList.pop();
         setPlayers(newList);
      }
   };

   const getColorsCallback = useCallback(async gameSelected => {
      const colorsResult = await selectionScreenApi.getColors();

      if (gameSelected.competitivetype === "Team") {
         setColors([
            colorsResult.data.data[0],
            colorsResult.data.data[1],
            colorsResult.data.data[0],
            colorsResult.data.data[1],
            colorsResult.data.data[0],
         ]);
      } else {
         setColors(colorsResult.data.data.sort(({ userorder: a }, { userorder: b }) => a - b)); // ordonarea culorilor dupa prop 'userorder'
      }
   }, []);

   const getGamesCallback = useCallback(async () => {
      const gamesResult = await selectionScreenApi.getGames(roomId, getGameType());
      setGamesList(gamesResult.data);
      setGameSelected(gamesResult.data[0]); //primul joc este preselectat pentru a aduce level-urile
   }, [gameTypeIsCooperative]);

   const getLevelsByGameCallback = useCallback(
      async gameSelected => {
         const gamesResult = await selectionScreenApi.getLevelsByGame(roomId, gameSelected.id, getGameType());
         setLevels(gamesResult.data.data[0].levelsList);
      },
      [gameTypeIsCooperative],
   );

   const getCardInfoAndScore = async (cardUid, gameSelected, currentLevelIndex) => {
      try {
         const cardInfoResult = await selectionScreenApi.getCardInfo(cardUid);

         if (cardInfoResult.data.data.length !== 0) {
            const scoreResult = await selectionScreenApi.getPlayerScore(roomId, gameSelected.id, currentLevelIndex, cardUid, getGameType());
            if (scoreResult.data.data) {
               if (!scoreResult.data.data[0]) {
                  scoreResult.data.data[0] = {
                     timeLeft: {
                        secondsRemains: 1800,
                     },
                     highUserScore: 0,
                     tokenUser: 0,
                     tokenReward: 0,
                     highScoreToday: 0,
                     highScore90Days: 0,
                     highScoreAllTime: 0,
                     playerLevelId: 0,
                     userData: {
                        timeslot: cardInfoResult.data.data[0].timeslot,
                        uid: cardInfoResult.data.data[0].uid,
                        userid: {
                           id: cardInfoResult.data.data[0].userid.id,
                           email: "",
                           nickname: cardInfoResult?.data?.data[0]?.userid?.nickname,
                        },
                     },
                  };
               } else {
                  scoreResult.data.data[0].userData = cardInfoResult.data.data[0];
               }

               let playerToAdd = scoreResult.data.data[0];
               handlePlayersList(playerToAdd);
               setLevelIsDisabled(false);
               // setSelectedLevel(0);
               if (getGameType() == "COOPERATIVE") {
                  setHighScore(scoreResult.data.data[0]);
               }
               // setCurrentCardUid("");
            }
         } else {
            alert(cardInfoResult.data.status.description);
         }
      } catch (error) {
         const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
         alert(resMessage);
      }
   };

   //play sound
   useEffect(() => {
      const playAudioElements = document.getElementsByClassName("button-sound");

      for (let i = 0; i < playAudioElements.length; i++) {
         playAudioElements[i].addEventListener("click", playAudio);
      }
      return () => {
         for (let i = 0; i < playAudioElements.length; i++) {
            playAudioElements[i].removeEventListener("click", playAudio);
         }
      };
   }, []);

   useEffect(() => {
      localApi.setLeds(players.filter(x => x.userData !== undefined).length);
      setMaxLevelSlider(helpers.getMaxLevel(players, levels));
   }, [players]);

   useEffect(() => {
      getGamesCallback();
   }, [gameTypeIsCooperative]);

   useEffect(() => {
      if (gameSelected) {
         getLevelsByGameCallback(gameSelected);
         if (!gameTypeIsCooperative) {
            getColorsCallback(gameSelected);
         }
      }
   }, [gameSelected]);

   useEffect(() => {
      if (currentCardUid?.length === 10 && gameSelected) {
         getCardInfoAndScore(currentCardUid, gameSelected, levels[selectedLevel].id);
      }
   }, [currentCardUid, gameSelected]);

   // send request-> scan bracelet -> get cardUid -> at every deviceSettings.scanInterval (set it from settings page) (default 1sec)
   useEffect(() => {
      const scanBraceletInterval = setInterval(async () => {
         const responseFromScan = await selectionScreenApi.scanBraceletTroughAPI();
         const idBracelet = responseFromScan?.id;

         if (responseFromScan !== "Not found.") setCurrentCardUid(responseFromScan?.data);

         if (idBracelet && currentCardUid && currentCardUid !== undefined && currentCardUid !== "Not found.") {
            setSelectedLevel(0); // resetam level-ul selectat cand se scaneaza un nou card
            const isDeletedFromScan = await selectionScreenApi.deleteBraceletTroughAPI(idBracelet);
            if (isDeletedFromScan === 0) setCurrentCardUid("");
         }
      }, DeviceSettings.scanInterval);
      return () => clearInterval(scanBraceletInterval);
   }, [currentCardUid]);

   //optimizare incarcare imagini
   useEffect(() => {
      try {
         if (gameSelected.id) {
            const imageHowToPlay = new Image();
            imageHowToPlay.src = require(`../../assets/img/howToPlay/imageHowToPlay${gameSelected.id}.webp`);
            setImageHowToPlayImage(imageHowToPlay.src);

            const imageXPopUp = new Image();
            imageXPopUp.src = require(`../../assets/img/howToPlay/X_Pop_up_${helpers.getGameType(gameTypeIsCooperative)}.webp`);
            setImageHowToPlayClose(imageXPopUp.src);

            return () => {
               imageHowToPlay.remove();
               imageXPopUp.remove();
            };
         }
      } catch (error) {
         console.error(`Imaginea imageHowToPlay.webp nu există pe server.`);
      }
   }, [gameSelected, gameTypeIsCooperative]);

   const showGames = () => {
      return <GameSelectionCarousel games={gamesList} gameTypeIsCooperative={gameTypeIsCooperative} playAudio={playAudio} />;
   };

   const getButton = () => {
      const noOfPlayer = players.filter(x => x.userData !== undefined).length;
      if (noOfPlayer < 2) {
         return (
            <div className="text-center show-btn-gray">
               <p className={"text-c1 mt-4 mb-0"}>Scan bracelets to register players!</p>
               <img className={"w-65 top--11 position-relative"} src={StartBtn} rel="preload" />
            </div>
         );
      } else {
         return (
            <div className="text-center">
               <img
                  className={"w-65 top-10 position-relative button-sound"}
                  onClick={handleStartGame}
                  src={gameTypeIsCooperative ? StartBtn2 : StartBtnRed}
                  rel="preload"
               />
            </div>
         );
      }
   };

   const gameSelectionScreen = () => {
      const highScoreToday = highScore.highScoreToday;
      const highScore90Days = highScore.highScore90Days;
      const highScoreAllTime = highScore.highScoreAllTime;

      return (
         <div className={gameTypeIsCooperative ? "screen-container score-green" : "screen-container score-red"}>
            <div className="container">
               <div className="row">
                  <div className="col-md-6">
                     <div className="pl12 pr12 text-center pt6 pb6 position-relative">
                        <img className={"w-80"} src={gameTypeIsCooperative ? CooperativeTxtGreen : CooperativeTxtRed} rel="preload" />
                        <img
                           className={"arr-coop coop-left button-sound"}
                           src={gameTypeIsCooperative ? CooperativeGreenLeft : CooperativeRedLeft}
                           onClick={handleClickCooperative}
                           rel="preload"
                        />
                        <img
                           className={"arr-coop coop-right button-sound"}
                           src={gameTypeIsCooperative ? CooperativeGreenRight : CooperativeRedRight}
                           onClick={handleClickCooperative}
                           rel="preload"
                        />
                     </div>
                     <div className="score main-border">
                        {gameTypeIsCooperative ? (
                           <TableSelectionScreen players={players} />
                        ) : (
                           <TableSelectionScreenCompetitive players={players} colors={colors} />
                        )}

                        {gameTypeIsCooperative && (
                           <div className="m1 font-weight-500">
                              <p className={"font2 font-weight-500"}>HIGH SCORES</p>
                              <div className="row ">
                                 <div className="col-md-4">
                                    <p className={"mb-0"}>Today</p>
                                    <div className={"second-border bg-light1 p1"}>{highScoreToday}</div>
                                 </div>
                                 <div className="col-md-4">
                                    <p className={"mb-0"}>90 Days</p>
                                    <div className={"second-border bg-light1 p1"}>{highScore90Days}</div>
                                 </div>
                                 <div className="col-md-4">
                                    <p className={"mb-0"}>All-Time</p>
                                    <div className={"second-border bg-light1 p1"}>{highScoreAllTime}</div>
                                 </div>
                              </div>
                           </div>
                        )}

                        <div className="m1 font-weight-500">
                           <div className="col-md-4 p2 pb-2 pt-4 m-auto font-17 div-btn-reset">
                              <p className={"btn-play w-100 button-sound mb-0"} onClick={handleResetTable}>
                                 RESET
                              </p>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="col-md-6 mt4">
                     <div className="score main-border">
                        <p className={"font-26 font-weight-500 pt8 pb6"}>GAME SELECTION</p>
                        <div className="m1 font-weight-500 pl6 pr6 position-relative">
                           <div className="row ">
                              <GameSelectedContext.Provider
                                 value={{
                                    gameSelected,
                                    setGameSelected,
                                    playAudio,
                                 }}
                              >
                                 {showGames()}
                              </GameSelectedContext.Provider>
                           </div>
                           <div className="col-md-5 p2 pb-2 pt12 m-auto font-17">
                              <a
                                 onClick={() => {
                                    setImageHowToPlayShow(true);
                                    playAudio();
                                 }}
                                 className={"btn-play w-100 button-sound"}
                              >
                                 How to play
                              </a>
                              {imageHowToPlayShow && (
                                 <div id="modalHowToPlay">
                                    <div onClick={() => setImageHowToPlayShow(false)} className="modal-how-to-play">
                                       <img rel="preload" src={imageHowToPlayImage} />
                                       <img rel="preload" className="button-x-modal" src={imageHowToPlayClose} />
                                    </div>
                                 </div>
                              )}
                           </div>
                           <div className={!gameTypeIsCooperative ? "hidden" : ""}>
                              <p className={"font2 font-weight-500 pt4"}>LEVEL</p>
                              <div className="pb4">
                                 <div className="slider-level">
                                    <SliderLevel
                                       maxLevelSlider={maxLevelSlider}
                                       onChange={handleIndexChange}
                                       currentIndex={selectedLevel}
                                       levelIsDisabled={levelIsDisabled}
                                       playAudio={playAudio}
                                    />
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     {getButton()}
                  </div>
               </div>
            </div>
         </div>
      );
   };

   return (
      <React.Fragment>
         {screenStatus === SelectionScreenStatus.GameSelection && gameSelectionScreen()}
         {screenStatus === SelectionScreenStatus.WaitingGameToStart && (
            <SelectionScreenMessage imgSrc={gameTypeIsCooperative ? MessageCooperative : MessageCompetitive} />
         )}
         {screenStatus === SelectionScreenStatus.Countdown && (
            <div className="screen-container ">
               <div className={`tv-slider ${gameTypeIsCooperative ? "green-template" : "red-template"}  full-slider`}>
                  <div className="container">
                     <div className="row pl8 pr8">
                        <div className="col-md-12">
                           <div style={{ margin: "auto", width: "50%" }}>
                              <SimpleCountDown
                                 timeClassNames={"text-counter"}
                                 duration={CountDownDurationInMiliseconds / 1000}
                                 startFrom={CountDownDurationInMiliseconds / 1000}
                                 imgGo={gameTypeIsCooperative ? GoCooperative : GoCompetitive}
                                 template={gameTypeIsCooperative ? "green" : "red"}
                              />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         )}
      </React.Fragment>
   );
};

export default Selection;
