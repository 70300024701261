import React, { useCallback, useEffect, useState } from "react";
import Bracelet from "../assets/img/Scan_bracelet.webp";
import { useNavigate } from "react-router-dom";
import authService from "../api/auth.service";
import selectionScreenApi from "../api/selection.screen";
import AuthService from "../api/auth.service";
import axios from "axios";


const ScanBracelet = (props) => {
   const [valueToSave, setValueToSave] = useState("");
   const [cardInfo, setCardInfo] = useState([]);

   const API_URL = "https://uvvo.net:8443/EnergizeGamesServer/api/v1/";

   // const getGames = (roomId = 1, gameType = 'COOPERATIVE') => {
   //     let user = AuthService.getCurrentUser();
   //     const headers = {
   //         'Authorization': 'Bearer ' + user.token
   //     };
   //
   //     return axios
   //         .get(API_URL  + "rooms/" + roomId + "/games?gameType=" + gameType,{headers})
   //         .then((response) => {
   //             const gam = response.data.data[0];
   //
   //         }).then((gam) => {
   //             axios.get(`https://jsonplaceholder.typicode.com/users`)
   //                 .then(res => {
   //                     // console.log('dasda', response.data.data[0])
   //                     const persons = res.data;
   //                   console.log(persons)
   //                     console.log(gam);
   //                 })
   //         });
   // };

   const getCardInfoAndAssignToUser = (cardUid = 4028250148) => {
      let user = AuthService.getCurrentUser();

      const headers = {
         "Authorization": "Bearer " + user.token,
      };

      return axios
         .get(API_URL + "cards?cardUID=" + cardUid, { headers })
         .then((response) => {
            const card = response.data.data[0];
            const res = authService.setCardToUser(card.id).then(response => {
               console.log(response.data.data[0]);
            });


         });
   };

   useEffect(() => {
      getCardInfoAndAssignToUser();
   }, []);


   return (

      <div className="tablet-container">

      </div>);
};

export default ScanBracelet;
