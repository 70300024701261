export const AccountSummaryRankType = {
   Previous: "PREVIOUS RANK",
   Current: "CURRENT RANK",
   Next: "NEXT RANK",
};
export const AccountSummaryCardTheme = {
   Grey: {
      backgroundImageCard: `url(${require("assets/img/checkscore/p_g.webp")})`,
      backgroundImageNumber: `url(${require("assets/img/checkscore/p_n.webp")})`,
      rewardTextColor: "#BDBDBD",
   },
   Violet: {
      backgroundImageCard: `url(${require("assets/img/checkscore/g1.webp")})`,
      backgroundImageNumber: `url(${require("assets/img/checkscore/n1.webp")})`,
      rewardTextColor: "#00E646",
   },
   Orange: {
      backgroundImageCard: `url(${require("assets/img/checkscore/g2.webp")})`,
      backgroundImageNumber: `url(${require("assets/img/checkscore/n2.webp")})`,
      rewardTextColor: "#FEB14E",
      lockIcon: require("assets/img/checkscore/i2.webp"),
   },
   Blue: {
      backgroundImageCard: `url(${require("assets/img/checkscore/g3.webp")})`,
      backgroundImageNumber: `url(${require("assets/img/checkscore/n3.webp")})`,
      rewardTextColor: "#548DFF",
      lockIcon: require("assets/img/checkscore/i3.webp"),
   },
   Red: {
      backgroundImageCard: `url(${require("assets/img/checkscore/g4.webp")})`,
      backgroundImageNumber: `url(${require("assets/img/checkscore/n4.webp")})`,
      rewardTextColor: "#FE5959",
      lockIcon: require("assets/img/checkscore/i4.webp"),
   },
   Yellow: {
      backgroundImageCard: `url(${require("assets/img/checkscore/g5.webp")})`,
      backgroundImageNumber: `url(${require("assets/img/checkscore/n5.webp")})`,
      rewardTextColor: "#FFF780",
      lockIcon: require("assets/img/checkscore/i5.webp"),
   },
   Green: {
      backgroundImageCard: `url(${require("assets/img/checkscore/g6.webp")})`,
      backgroundImageNumber: `url(${require("assets/img/checkscore/n6.webp")})`,
      rewardTextColor: "#00E646",
      lockIcon: require("assets/img/checkscore/i6.webp"),
   },
   Pink: {
      backgroundImageCard: `url(${require("assets/img/checkscore/g7.webp")})`,
      backgroundImageNumber: `url(${require("assets/img/checkscore/n7.webp")})`,
      rewardTextColor: "#FF66D8",
      lockIcon: require("assets/img/checkscore/i7.webp"),
   },
   Cyan: {
      backgroundImageCard: `url(${require("assets/img/checkscore/g8.webp")})`,
      backgroundImageNumber: `url(${require("assets/img/checkscore/n8.webp")})`,
      rewardTextColor: "#66FFE5",
      lockIcon: require("assets/img/checkscore/i8.webp"),
   },
};
