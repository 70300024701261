import React, { useState, useRef } from "react";
import SliderCountTime from "../../../components/Sliders/SliderCountTime";
import SliderCountTimeSmall from "../../../components/Sliders/SliderCountTimeSmall";
import Heart from "../../../assets/img/Heart_empty.webp";
import HeartFull from "../../../assets/img/Heart_full.webp";
import imgLevelFailed from "../../../assets/img/Level_Failed.webp";
import imgEarned from "../../../assets/img/You_Have_Earned.webp";

const Ball = props => {
   return (
      // bg-screen-red/blue
      <div className="screen-container ">
         {/*red-template in loc de green-template (pentru slider rosu) */}
         <div className="tv-slider green-template full-slider">
            <div className="container">
               {/*TEMPLATE TEXT LEVEL FAILED*/}
               {/*<div className="row justify-content-center">*/}
               {/*    <div className="col-md-6">*/}
               {/*        <div className="w-100">*/}
               {/*            <img className={"w-100"} src={imgLevelFailed} />*/}
               {/*        </div>*/}
               {/*    </div>*/}
               {/*</div>*/}

               {/*TEMPLATE TEXT YOU HAVE EARNED*/}
               {/*<div className="row justify-content-center">*/}
               {/*    <div className="col-md-6">*/}
               {/*        <div className="w-100">*/}
               {/*            <img className={"w-100"} src={imgLevelFailed} />*/}
               {/*            <br/>*/}
               {/*            <p className={'text-team font3 font-weight-500'}>2587 points</p>*/}
               {/*        </div>*/}
               {/*    </div>*/}
               {/*</div>*/}

               {/*<div className="row row-heart">*/}
               {/*    <div className="col-md-12">*/}
               {/*        <img src={Heart}/>*/}
               {/*        <img src={Heart}/>*/}
               {/*        <img src={HeartFull}/>*/}
               {/*        <img src={HeartFull}/>*/}
               {/*        <img src={HeartFull}/>*/}
               {/*    </div>*/}
               {/*</div>*/}
               <div className="row pl8 pr8">
                  {/* <div className="col-md-6">
                     <SliderCountTime />
                  </div> */}
                  {/* <div className="col-md-6 div-score"> */}
                  {/* TEMPLATE TEAM + LIVES LEFT*/}
                  {/*<div className="w-100">*/}
                  {/*    <p className={"text-team"}>Team 1*/}
                  {/*        <span>*/}
                  {/*            lives left*/}
                  {/*        </span>*/}
                  {/*    </p>*/}
                  {/*    <div className="div-sc sc-red progress-sc progress-50">18</div>*/}
                  {/*</div>*/}
                  {/*<div className="w-100">*/}
                  {/*    <p className={"text-team"}>Team 2*/}
                  {/*        <span>*/}
                  {/*            lives left*/}
                  {/*        </span>*/}
                  {/*    </p>*/}
                  {/*    <div className="div-sc sc-green progress-sc progress-50">18</div>*/}
                  {/*</div>*/}

                  {/* TEMPLATE Cerc*/}
                  {/* <div className="div-cerc">
                        <div className="cerc sc-red"></div>
                        <div className="cerc sc-green"></div>
                        <div className="cerc sc-yellow"></div>
                        <div className="cerc"></div>
                        <div className="cerc"></div>
                        <div className="cerc"></div>
                        <div className="cerc"></div>
                        <div className="cerc"></div>
                     </div> */}

                  {/*<div className="div-sc sc-red progress-sc progress-50">18</div>*/}
                  {/*<div className="div-sc sc-green progress-sc progress-50">16</div>*/}
                  {/*<div className="div-sc sc-blue progress-sc progress-50">11</div>*/}
                  {/*<div className="div-sc sc-yellow progress-sc progress-50">8</div>*/}
                  {/*<div className="div-sc sc-purple progress-sc progress-50">5</div>*/}

                  {/*TEMPLATE "WHAT IS THE CAPITAL..."*/}
                  {/*<p className={"text-tv font3 text-center"}>What is the capital of France?</p>*/}
                  {/*<div className="div-question">*/}
                  {/*    <div className="div-sc skew-0 sc-red progress-sc progress-100">Athens</div>*/}
                  {/*    <div className="div-sc skew-0 sc-blue progress-sc progress-100">Berlin</div>*/}
                  {/*    <div className="div-sc skew-0 sc-green progress-sc progress-100">Paris</div>*/}
                  {/*    <div className="div-sc skew-0 sc-yellow progress-sc progress-100">New York</div>*/}
                  {/*    <div className="div-sc skew-0 sc-purple progress-sc progress-100">Barcelona</div>*/}
                  {/*</div>*/}
                  {/*<div className="div-sc sc-green font-15 progress-sc progress-50 mt4">8/15</div>*/}

                  {/*TEMPLATE COMPETITIVE */}
                  {/*<div className="div-sc sc-red progress-sc progress-50">18</div>*/}
                  {/*<div className="div-sc sc-green progress-sc progress-50">16</div>*/}
                  {/*<div className="div-sc sc-blue progress-sc progress-50">11</div>*/}
                  {/*<div className="div-sc sc-yellow progress-sc progress-50">8</div>*/}
                  {/*<div className="div-sc sc-purple progress-sc progress-50">5</div>*/}

                  {/*TEMPLATE COMPETITIVE nickname */}
                  {/*<div className="div-question">*/}
                  {/*    <div className="div-mini-slider mini-slider-green">*/}
                  {/*        <p>nickname1</p>*/}
                  {/*        <SliderCountTimeSmall />*/}
                  {/*    </div>*/}
                  {/*    <div className="div-mini-slider mini-slider-red">*/}
                  {/*        <p>nickname2</p>*/}
                  {/*        <SliderCountTimeSmall />*/}
                  {/*    </div>*/}
                  {/*    <div className="div-mini-slider mini-slider-blue">*/}
                  {/*        <p>nickname3</p>*/}
                  {/*        <SliderCountTimeSmall />*/}
                  {/*    </div>*/}
                  {/*    <div className="div-mini-slider mini-slider-yellow">*/}
                  {/*        <p>nickname4</p>*/}
                  {/*        <SliderCountTimeSmall />*/}
                  {/*    </div>*/}
                  {/*    <div className="div-mini-slider mini-slider-purple">*/}
                  {/*        <p>nickname5</p>*/}
                  {/*        <SliderCountTimeSmall />*/}
                  {/*    </div>*/}
                  {/*</div>*/}

                  {/*WARZONE - COMPETITIVE */}
                  {/*<div className="w-100">*/}
                  {/*    <p className={'text-team text-left mb-1'}>team1</p>*/}
                  {/*    <div className="div-sc sc-red progress-sc progress-100">18</div>*/}
                  {/*</div>*/}
                  {/*<div className="w-100">*/}
                  {/*    <p className={'text-team text-left mb-1'}>team2</p>*/}
                  {/*    <div className="div-sc sc-green progress-sc progress-100">16</div>*/}
                  {/*</div>*/}
                  {/*<div className="w-100">*/}
                  {/*    <p className={'text-team text-left mb-1'}>team3</p>*/}
                  {/*    <div className="div-sc sc-blue progress-sc progress-100">11</div>*/}
                  {/*</div>*/}
                  {/*<div className="w-100">*/}
                  {/*    <p className={'text-team text-left mb-1'}>team4</p>*/}
                  {/*    <div className="div-sc sc-yellow progress-sc progress-100">8</div>*/}
                  {/*</div>*/}
                  {/*<div className="w-100">*/}
                  {/*    <p className={'text-team text-left mb-1'}>team5</p>*/}
                  {/*    <div className="div-sc sc-purple progress-sc progress-100">5</div>*/}
                  {/*</div>*/}

                  {/*BUTTONS COOPERATIVE*/}
                  {/* <div className="w-100">
                        <p className={"text-team"}>
                           <span className={"font-15"}>mines left</span>
                        </p>
                        <div className="div-sc sc-green progress-sc progress-50">2/3</div>
                     </div> */}

                  {/*TUBES*/}
                  {/*<div className="w-100">*/}
                  {/*    <p className={"text-team font2"}>EUHOS</p>*/}
                  {/*</div>*/}

                  <div className="w-100">
                     <img className={"w-50 d-block m-auto"} src={imgLevelFailed} />
                  </div>
                  {/* </div> */}
               </div>
            </div>
         </div>
      </div>
   );
};

export default Ball;
