export const API_URL = "https://uvvo.net:8443/EnergizeGamesServer/api/v1/";
export const LOCAL_API_URL = "http://localhost/";

export const ActiveGameStatus = {
   WAITING_ROOM: "WAITING_ROOM",
   WAITING_START: "WAITING_START",
   RUNNING: "RUNNING",
   COMPLETE_SUCCESS: "COMPLETE_SUCCESS",
   COMPLETE_FAILURE: "COMPLETE_FAILURE",
};

export const ApiRetryTimeout = 250;
export const NoResultStatusCode = 5;
export const OkResultStatusCode = 0;

export const DeviceSettings = {
   //daca exista deviceId, il folosim, altfel folosim unul default pentru testare
   deviceID: JSON.parse(localStorage.getItem("deviceSettings"))?.deviceID
      ? JSON.parse(localStorage.getItem("deviceSettings"))?.deviceID
      : "01:01:01:01:01:01",
   // daca este setat scanInterval, il folosim, altfel folosim 1000ms
   scanInterval: JSON.parse(localStorage.getItem("deviceSettings"))?.scanInterval
      ? JSON.parse(localStorage.getItem("deviceSettings"))?.scanInterval
      : 1000,
   nickname: JSON.parse(localStorage.getItem("deviceSettings"))?.nickname ? JSON.parse(localStorage.getItem("deviceSettings"))?.nickname : "alin33",
   password: JSON.parse(localStorage.getItem("deviceSettings"))?.password ? JSON.parse(localStorage.getItem("deviceSettings"))?.password : "test11",
   checkScoreScanInterval: 60000,
   reLoginInterval: 1800000, //0,5h
};


// TODO bogdanla: To be discussed at the end

// START RECURRENT CALL TO WAITING_START
// -> IF yes return CountDown
// -> If no show IdleScreen
// START RECURRENT CALL TO RUNNING
// -> IF yes return GameCountDown
// -> If no show IdleScreen
// START RECURRENT CALL TO COMPLETE_SUCCESS
