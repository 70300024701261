import React, { useEffect } from "react";
import ReactSlider from "react-slider";

const Slider = ({ onChange, currentIndex, levelIsDisabled, maxLevelSlider, playAudio }) => {
   useEffect(() => {
      const playAudioElements = document.getElementsByClassName("button-sound");

      for (let i = 0; i < playAudioElements.length; i++) {
         playAudioElements[i].addEventListener("touchend", playAudio);
      }
      return () => {
         for (let i = 0; i < playAudioElements.length; i++) {
            playAudioElements[i].removeEventListener("touchend", playAudio);
         }
      };
   }, []);
   return (
      <ReactSlider
         thumbClassName="example-thumb"
         markClassName="example-mark"
         onChange={value => {
            if (value > maxLevelSlider - 1) {
               return;
            }
            onChange(value);
         }}
         trackClassName="example-track"
         defaultValue={2}
         value={currentIndex}
         min={0}
         max={9}
         disabled={levelIsDisabled}
         marks
         renderMark={props => {
            if (props.key > maxLevelSlider - 1) {
               props.className = "example-mark example-mark-disabled";
            } else if (props.key === currentIndex) {
               props.className = "example-mark example-mark-active";
            }
            return <span {...props}> {props.key + 1}</span>;
         }}
         className="horizontal-slider button-sound"
      />
   );
};

export default Slider;
