import { isEqual } from "lodash";
import { useState } from "react";

export const useMemoizedState = initialValue => {
   const [state, _setState] = useState(initialValue);

   const setState = newState => {
      _setState(prev => {
         if (!isEqual(newState, prev)) {
            return newState;
         } else {
            return prev;
         }
      });
   };

   return [state, setState];
};
