import React from "react";
import { Link } from "react-router-dom";
import Home from "../assets/img/tap_to_begin.webp";


const Homepage = (props) => {
   window.localStorage.clear();

   return (<div className="tablet-container">
      <Link to={"/login"} className={"link-tablet"}>
         <img className="w-100" src={Home} />
      </Link>
   </div>);
};

export default Homepage;
