import React, { useState, useEffect } from "react";
import { getAllGamesForRoom } from "api/checkscore";
import helpers from "helpers/helpers";
import { getScores } from "api/checkscore";
import { isObject } from "lodash";

const GamesRankings = ({ gameTypeIsCooperative, selectedRoom, roomId, gameId, setGameId, userCheckScore }) => {
   const [games, setGames] = useState([]);
   const [selectedGame, setSelectedGame] = useState("");
   const [scores, setScores] = useState({});

   const onClickGame = game => {
      setSelectedGame(game.displayname);
      setGameId(game.id);
   };

   const renderTableScores = () => {
      if (isObject(scores)) {
         return Object.keys(scores).map((key, index) => {
            return (
               <tr key={key}>
                  <td>{index + 1}</td>
                  {scores[key].yourPoints === 0 ? <td className="cff3b1">No score</td> : <td>{scores[key].yourPoints}</td>}
                  {scores[key].topPoints === 0 ? <td className="cff3b1">No score</td> : <td>{scores[key].topPoints}</td>}
               </tr>
            );
         });
      }
   };

   useEffect(() => {
      const getAllGamesForRoomAsync = async () => {
         const response = await getAllGamesForRoom(roomId, helpers.getGameType(gameTypeIsCooperative));
         setGames(response);
         setSelectedGame(response[0].displayname);
         setGameId(response[0].id);
      };

      getAllGamesForRoomAsync();
   }, [roomId, gameTypeIsCooperative]);

   useEffect(() => {
      const getScoresAsync = async () => {
         const response = await getScores(gameId, roomId, userCheckScore.cardUid);
         setScores(response);
      };

      getScoresAsync();
   }, [gameId]);

   return (
      <div className={` pt3 pb3 ${gameTypeIsCooperative ? " checkscore-table-games-green" : " checkscore-table-games-red"}`}>
         <div className="col-md-12 pt-3">
            <p className="font4 text-white font-weight-600 text-center text-uppercase">{selectedRoom}</p>
         </div>
         <div className="pb-3">
            <ul className="nav nav-tabs games_in_tab" role="tablist">
               {games.map(game => {
                  return (
                     <li role="presentation" key={game.id}>
                        <a
                           onClick={() => onClickGame(game)}
                           className={`font2 font-style-normal text-white font-weight-600 text-center mb-0 
                     ${
                        selectedGame !== game.displayname && gameTypeIsCooperative === true
                           ? "cff3b1"
                           : selectedGame !== game.displayname && gameTypeIsCooperative === false
                           ? "ffbfbf"
                           : "active"
                     }`}
                           role="tab"
                           data-toggle="tab"
                        >
                           {game.displayname}
                        </a>
                     </li>
                  );
               })}
            </ul>
         </div>
         {gameTypeIsCooperative ? (
            <div className="games-table">
               <table className="table text-white">
                  <thead>
                     <tr>
                        <th scope="col">Level</th>
                        <th scope="col">Your Score</th>
                        <th scope="col">Top Score</th>
                     </tr>
                  </thead>
                  <tbody>{renderTableScores()}</tbody>
               </table>
            </div>
         ) : (
            <div className="text-white text-center font-weight-600">
               <div className="row">
                  <div className="col-md-4 d-flex flex-column">
                     <p>
                        Game wins <br />
                        today
                     </p>
                     <p className="font-weight-400">2</p>
                  </div>
                  <div className="col-md-4 d-flex flex-column">
                     <p>
                        All time
                        <br /> game wins
                     </p>
                     <p className="font-weight-400">104</p>
                  </div>
                  <div className="col-md-4 d-flex flex-column">
                     <p>
                        Most wins
                        <br /> in one day
                     </p>
                     <p className="font-weight-400">28</p>
                  </div>
               </div>
            </div>
         )}
      </div>
   );
};

export default GamesRankings;
