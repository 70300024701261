import React, { useEffect, useState } from "react";
import ImgBack from "../assets/img/Tablet_screen_Back_Btn.webp";
import Waiver from "../components/modals/Waiver";
import RegTxt from "../assets/img/Register2.webp";
import RegBtn from "../assets/img/Register.webp";
import AuthService from "../api/auth.service";
import { Link, useNavigate } from "react-router-dom";
import Border from "../components/Border";

const inputTypes = {
   nickname: "nickname",
   email: "email",
   confirmEmail: "confirmEmail",
   password: "password",
   confirmPassword: "confirmPassword",
   age: "age",
   waiverName: "waiverName",
   waiverSurname: "waiverSurname",
};

const Register = props => {
   const [show, setShow] = useState(false);
   const [disableSubmit, setDisableSubmit] = useState(false);
   const [form, setForm] = useState({
      nickname: { value: "", error: undefined },
      email: { value: "", error: undefined },
      confirmEmail: { value: "", error: undefined },
      password: { value: "", error: undefined },
      confirmPassword: { value: "", error: undefined },
      waiver: { value: false, error: undefined },
      age: { value: null, error: undefined },
      waiverName: { value: "", error: undefined },
      waiverSurname: { value: "", error: undefined },
   });

   const [isWaiverSubmitable, setIsWaiverSubmitable] = useState(false);
   const [waiverData, setWaiverData] = useState({});

   const navigate = useNavigate();

   const onSubmit = data => {
      if (disableSubmit) return;
      setDisableSubmit(true);

      const hasError = validate();
      if (hasError) {
         setDisableSubmit(false);

         return;
      }
      AuthService.register(
         form.nickname.value,
         form.email.value,
         form.password.value,
         form.waiverName.value,
         form.waiverSurname.value,
         form.age.value,
         waiverData.id,
      ).then(
         response => {
            if (response.data.status.code === 0) {
               navigate("/registration-confirmed", {
                  state: {
                     nickname: form.nickname.value,
                     password: form.password.value,
                  },
               });
            } else {
               setForm({
                  ...form,
                  nickname: {
                     ...form.nickname,
                     error: "This nickname is already taken.",
                  },
               });
               setDisableSubmit(false);
            }
         },
         error => {
            const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            navigate("/error");
         },
      );
   };

   const handleInputChage = (value, type) => {
      if (type === inputTypes.nickname) {
         setForm({
            ...form,
            nickname: { value: value, error: undefined },
         });
      } else if (type === inputTypes.email) {
         setForm({
            ...form,
            email: { value: value, error: undefined },
         });
      } else if (type === inputTypes.confirmEmail) {
         setForm({
            ...form,
            confirmEmail: { value: value, error: undefined },
         });
      } else if (type === inputTypes.password) {
         setForm({
            ...form,
            password: { value: value, error: undefined },
         });
      } else if (type === inputTypes.confirmPassword) {
         setForm({
            ...form,
            confirmPassword: { value: value, error: undefined },
         });
      } else if (type === inputTypes.waiverName) {
         setForm({
            ...form,
            waiverName: { value: value, error: undefined },
         });
      } else if (type === inputTypes.waiverSurname) {
         setForm({
            ...form,
            waiverSurname: { value: value, error: undefined },
         });
      }
   };

   const validate = () => {
      const newErrors = {};

      newErrors.nickname = validateNickname();
      newErrors.email = validateEmail();
      newErrors.confirmEmail = validateConfirmEmail();
      newErrors.password = validatePassword();
      newErrors.confirmPassword = validateConfirmPassword();
      newErrors.waiver = validateWaiver();
      newErrors.waiverName = validateWaiverName();
      newErrors.waiverSurname = validateWaiverSurname();

      setForm({
         nickname: {
            ...form.nickname,
            error: newErrors.nickname,
         },
         email: {
            ...form.email,
            error: newErrors.email,
         },
         confirmEmail: {
            ...form.confirmEmail,
            error: newErrors.confirmEmail,
         },
         password: {
            ...form.password,
            error: newErrors.password,
         },
         confirmPassword: {
            ...form.confirmPassword,
            error: newErrors.confirmPassword,
         },
         waiver: {
            ...form.confirmPassword,
            error: newErrors.waiver,
         },
         age: {
            ...form.age,
            error: newErrors.age,
         },
         waiverName: {
            ...form.waiverName,
            error: newErrors.waiverName,
         },
         waiverSurname: {
            ...form.waiverSurname,
            error: newErrors.waiverSurname,
         },
      });

      if (newErrors.waiverName || newErrors.waiverSurname) setIsWaiverSubmitable(false);
      else setIsWaiverSubmitable(true);

      return (
         newErrors.nickname !== undefined ||
         newErrors.email !== undefined ||
         newErrors.confirmEmail !== undefined ||
         newErrors.password !== undefined ||
         newErrors.confirmPassword !== undefined ||
         newErrors.waiver !== undefined ||
         newErrors.waiverName !== undefined ||
         newErrors.waiverSurname !== undefined
      );
   };

   const validateNickname = () => {
      const nickname = form.nickname.value;

      if (nickname === undefined || nickname === "") {
         return "Please fill in this field correctly!";
      } else if (nickname.length < 2) {
         return `Minimum length is 2`;
      } else if (nickname.length > 12) {
         return `Maximum length is 12`;
      }
      return undefined;
   };

   const validateEmail = () => {
      const email = form.email.value;

      if (email === undefined || email === "") {
         return "Please fill in this field correctly!";
      } else if (new RegExp(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/).test(email) === false) {
         return "Invalid email address";
      }
      return undefined;
   };

   const validateConfirmEmail = () => {
      const email = form.email.value;
      const confirmEmail = form.confirmEmail.value;

      if (email === undefined || email === "") {
         return "Please fill in this field correctly!";
      } else if (email !== confirmEmail) {
         return "Your emails do no match";
      }
      return undefined;
   };

   const validatePassword = () => {
      const password = form.password.value;

      if (password === undefined || password === "") {
         return "Please fill in this field correctly!";
      } else if (password.length < 4) {
         return `Minimum length is 4`;
      }
      return undefined;
   };

   const validateConfirmPassword = () => {
      const password = form.password.value;
      const confirmPassword = form.confirmPassword.value;

      if (confirmPassword === undefined || confirmPassword === "") {
         return "Please fill in this field correctly!";
      } else if (password !== confirmPassword) {
         return "Your passwords do no match";
      }
      return undefined;
   };

   const validateWaiver = () => {
      const waiver = form.waiver.value;

      if (waiver !== true) {
         return "Please fill in this field correctly!";
      }

      return undefined;
   };

   const validateWaiverName = () => {
      const waiverName = form.waiverName.value;

      if (waiverName === undefined || waiverName === "") {
         return "Please fill in this field correctly!";
      } else if (waiverName.length < 2) {
         return `Minimum length is 2`;
      } else if (waiverName.length > 12) {
         return `Maximum length is 12`;
      }

      return undefined;
   };

   const validateWaiverSurname = () => {
      const waiverSurname = form.waiverSurname.value;

      if (waiverSurname === undefined || waiverSurname === "") {
         return "Please fill in this field correctly!";
      } else if (waiverSurname.length < 2) {
         return `Minimum length is 2`;
      } else if (waiverSurname.length > 12) {
         return `Maximum length is 12`;
      }

      return undefined;
   };

   const handleWaiverChange = value => {
      setForm({
         ...form,
         waiver: {
            value: value,
            error: undefined,
         },
      });
   };

   useEffect(() => {
      const getWaiverText = async () => {
         const response = await AuthService.getWaiverText();
         if (response.isSuccess) setWaiverData(response.response.data[0]);
         else navigate("/error");
      };

      getWaiverText();
   }, []);

   return (
      <div className={`${!show ? "main-container" : "hidden"}`}>
         <div className="text-center">
            <img className="w-img w-75 left-0" src={RegTxt} />
         </div>
         <div className="form-group">
            <Border />
            <Link to={"/login"}>
               <img className={"button-back button-back-register"} src={ImgBack} />
            </Link>
            <input
               onChange={e => handleInputChage(e.target.value, inputTypes.nickname)}
               placeholder="nickname"
               className={`${form.nickname.error !== undefined ? "is-invalid" : "is-valid"}`}
            />
            <br />
            <p className={"error-message m-0"}> {form.nickname.error !== undefined && form.nickname.error}</p>
            <br />
         </div>
         <div className="form-group">
            <Border />
            <input
               onChange={e => handleInputChage(e.target.value, inputTypes.email)}
               placeholder="email address"
               type="email"
               className={`${form.email.error !== undefined ? "is-invalid" : "is-valid"}`}
            />
            <br />
            <p className={"error-message m-0"}> {form.email.error !== undefined && form.email.error}</p>
            <br />
         </div>
         <div className="form-group">
            <Border />
            <input
               onChange={e => handleInputChage(e.target.value, inputTypes.confirmEmail)}
               placeholder="confirm email address"
               type="text"
               className={`${form.confirmEmail.error !== undefined ? "is-invalid" : "is-valid"}`}
            />
            <br />
            <p className={"error-message m-0"}> {form.confirmEmail.error !== undefined && form.confirmEmail.error}</p>
            <br />
         </div>
         <div className="form-group">
            <Border />
            <input
               onChange={e => handleInputChage(e.target.value, inputTypes.password)}
               placeholder="password"
               type="password"
               className={`${form.password.error !== undefined ? "is-invalid" : "is-valid"}`}
            />
            <br />
            <p className={"error-message m-0"}> {form.password.error !== undefined && form.password.error}</p>
            <br />
         </div>
         <div className="form-group mb-0">
            <Border />
            <input
               onChange={e => handleInputChage(e.target.value, inputTypes.confirmPassword)}
               placeholder="confirm password"
               type="password"
               className={`${form.confirmPassword.error !== undefined ? "is-invalid" : "is-valid"}`}
            />
            <br />
            <p className={"error-message m-0"}> {form.confirmPassword.error !== undefined && form.confirmPassword.error}</p>
            <br />
         </div>
         <div className="form-check mb4 d-flex">
            <input
               type="checkbox"
               className={`${form.waiver.error !== undefined ? "is-invalid" : "is-valid"} form-check-input mt3`}
               onChange={e => {
                  handleWaiverChange(e.target.checked);
                  if (e.target.checked) setShow(true);
               }}
               checked={form.waiver.value}
               id="flexCheckDefault"
            />
            <label className=" d-inline-block link ml2 mt2" htmlFor="flexCheckDefault">
               Tick to sign waiver
            </label>
         </div>
         <Waiver
            show={show}
            handleInputChage={handleInputChage}
            inputTypes={inputTypes}
            form={form}
            setForm={setForm}
            waiverText={waiverData.waiver}
            handleAccept={() => {
               if (form.age.value === null) {
                  setForm(prevState => {
                     return {
                        ...prevState,
                        age: {
                           ...prevState.age,
                           error: "Please fill in this field correctly!",
                        },
                     };
                  });
                  return;
               }

               onSubmit();
               if (isWaiverSubmitable) {
                  handleWaiverChange(true);
                  setShow(false);
               }
            }}
         />
         <div className="form-group">
            <input type="image" className="w-img bg-transparent" src={RegBtn} onClick={onSubmit} />
         </div>
      </div>
   );
};

export default Register;
